import React from "react";
import { Heading, ScaleFade } from "@chakra-ui/react";
import { ContributionModule } from "../../components/modules/ContributionModule";
import { ResidentExpirationModule } from "../../components/modules/ResidentExpirationModule";

export const ContributionsAdminScreen = () => {
  const startDate = new Date();
  const endDate = new Date();

  return (
    <>
      <ScaleFade initialScale={0.9} in={true}>
        <Heading mb={4}>Registrar Aportación</Heading>

        <ContributionModule startDate={startDate} endDate={endDate} />

        <ResidentExpirationModule />
      </ScaleFade>
    </>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  ScaleFade,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import { registerLocale } from "react-datepicker";
import { format } from "date-fns";
import { RenderLoading } from "../../components/RenderAlert";
import { useStore } from "../../hooks/useStore";
import { TablaSecurityHistory } from "../../components/tables/TablaSecurityHistory";
import { MessageBox } from "../../components/MessageBox";

registerLocale("es", es);

export const SecurityScreen = () => {
  const [isLoading, setisLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);

  const { securityHistory, getsSecurityHistory } = useStore();

  const handleDateChange = (date) => {
    setIsOpen(!isOpen);
    setSelectedDate(date);
  };

  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const formatDateToSend = (date) => {
    return format(date, "yyyy-MM-dd"); // Formatea la fecha como 'yyyy-MM-dd'
  };

  const handleGetHistory = async () => {
    try {
      const formattedDate = formatDateToSend(selectedDate);
      console.log(formattedDate);
      setisLoading(true);
      await getsSecurityHistory(formatDateToSend(selectedDate));
      setisLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchResidentInfo = async () => {
      setisLoading(true);
      await getsSecurityHistory(formatDateToSend(selectedDate));
      setisLoading(false);
    };

    fetchResidentInfo();
  }, []);

  return (
    <>
      {isLoading && <RenderLoading state={isLoading} />}

      <ScaleFade initialScale={0.9} in={true}>
        <Box>
          <Heading
            fontSize={{ base: "2xl", sm: "4xl" }}
            fontWeight="bold"
            mb={4}
          >
            Historial de Caseta
          </Heading>

          <Container maxW={"full"}>
            <Flex position="relative">
              <Button  mr={2} onClick={handleClick}>
                {format(selectedDate, "yyyy-MM-dd")}
              </Button>
              <Box
                position="absolute"
                top="calc(100% + 10px)"
                left="0"
                zIndex="999"
              >
                {isOpen && (
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    inline
                    maxDate={new Date()}
                    onClickOutside={handleClick}
                    locale="es"
                  />
                )}
              </Box>

              <Button onClick={handleGetHistory} colorScheme="twitter">Ver Historial</Button>
            </Flex>

            {securityHistory.length > 0 ? (
              <Box mt={4}>
                <TablaSecurityHistory securityHistory={securityHistory} selectedDate={selectedDate} />
              </Box>
            ) : (
              <Flex
                bg="white"
                borderRadius="lg"
                borderWidth={1}
                boxShadow="md"
                justify="center"
                mt={4}
                py={6}
                width="full"
              >
                <MessageBox
                  message="No hay historial que visualizar"
                  description="Seleccione una nueva fecha para ver tu historial"
                  status="info"
                />
              </Flex>
            )}
          </Container>
        </Box>
      </ScaleFade>
    </>
  );
};

import React from "react";
import {
  Alert,
  AlertTitle,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
} from "@chakra-ui/react";

const BaseAlert = ({ blockScroll, children, state }) => {
  return (
    <Modal blockScrollOnMount={blockScroll} isOpen={state} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
};

export const RenderLoading = ({ state, status = "success" }) => {
  return (
    <>
      <BaseAlert blockScroll={true} state={state}>
        <Alert
          status={status}
          backgroundColor="transparent"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="lg"
          />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Cargando información...
          </AlertTitle>
        </Alert>
      </BaseAlert>
    </>
  );
};
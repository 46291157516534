/* eslint-disable import/no-anonymous-default-export */
import {
  GET_CONTRIBUTIONS_INFO,
  GET_DASHBOARD_INFO,
  GET_RESIDENT_HISTORY,
  GET_STATUS_RESIDENT,
  GETS_ADDRESS_LIST,
  GETS_EXPIRATION_LIST,
  GETS_RESIDENTIAL,
  GETS_VEHICLES_AUTHORIZED,
  GETS_EXPENSES_LIST,
  GETS_CONTRIBUTIONS_LIST,
  GETS_SECURITY_HISTORY,
  GETS_REPORTS_HISTORY,
  GETS_GUARDS,
  GETS_DEFAULTERS,
} from "./actions";

export default (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case GETS_VEHICLES_AUTHORIZED:
      return {
        ...state,
        vehicles: payload,
      };

    case GETS_RESIDENTIAL:
      return {
        ...state,
        residential: payload,
      };

    case GET_DASHBOARD_INFO:
      return {
        ...state,
        dashboardInfo: payload,
      };

    case GET_CONTRIBUTIONS_INFO:
      return {
        ...state,
        contributionsInfo: payload,
      };

    case GET_STATUS_RESIDENT:
      return {
        ...state,
        statusResident: payload,
      };

    case GET_RESIDENT_HISTORY:
      return {
        ...state,
        residentHistory: payload,
      };

    case GETS_ADDRESS_LIST:
      return {
        ...state,
        address: payload,
      };

    case GETS_EXPIRATION_LIST:
      return {
        ...state,
        expirationList: payload,
      };

    case GETS_EXPENSES_LIST:
      return {
        ...state,
        expenses: payload,
      };

    case GETS_SECURITY_HISTORY:
      return {
        ...state,
        securityHistory: payload,
      };

    case GETS_REPORTS_HISTORY:
      return {
        ...state,
        reportsHistory: payload,
      };

    case GETS_GUARDS:
      return {
        ...state,
        guards: payload,
      };

    case GETS_DEFAULTERS:
      return {
        ...state,
        defaulters: payload,
      };

    case GETS_CONTRIBUTIONS_LIST:
      return {
        ...state,
        contributions: payload,
      };

    default:
      return state;
  }
};

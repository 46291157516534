import axios from "axios";
import { getAuthToken } from "../service/AuthService";

export const API = axios.create({
  //baseURL: "http://www.demoioi.somee.com/villalba",

  //baseURL: "http://localhost:5288/villalba",

  //Produccion
  baseURL: "http://ApiResidencial04.somee.com/villalba",
});

API.interceptors.request.use(
  (config) => {
    const token = getAuthToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Button,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import { API } from "../../api/config";

const validationSchema = Yup.object().shape({
  nombre: Yup.string().required("Campo requerido"),
  telefono: Yup.string().required("Campo requerido"),
  correo: Yup.string().email("Correo inválido").required("Campo requerido"),
  password: Yup.string().required("Campo es obligatorio"),
  password1: Yup.string()
    .oneOf([Yup.ref("password"), null], "Las contraseñas no coinciden")
    .required("Campo es obligatorio"),
});

const initialValues = {
  nombre: "",
  telefono: "",
  correo: "",
  password: "",
};

export const RegisterNewUser = ({ idResidente, direccion }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const formatToCapitalize = (text) => {
    return text
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleConfirmation = () => {
    setIsConfirmationOpen(true);
  };

  const handleCloseConfirmation = () => {
    setIsConfirmationOpen(false);
  };

  const handleNameChange = (event, form) => {
    const capitalizedName = formatToCapitalize(event.target.value);
    form.setFieldValue("nombre", capitalizedName);
  };

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleRegister = async (values, { setSubmitting, resetForm }) => {
    const url = `/residente/record?id=${idResidente}`;
    try {
      const dir = direccion?.trim();
      const formData = {
        nombre: values.nombre.trim(),
        correo: values.correo.trim(),
        password: values.password.trim(),
        telefono: values.telefono.toString(),
        direccion: dir,
      };
      setSubmitting(true);
      await API.post(url, formData);
      setSubmitting(false);
      resetForm(initialValues);
      sessionStorage.removeItem("residente");
      navigate("/auth/login", { replace: true });

      toast({
        description: "Se ha registrado exitosamente.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      setSubmitting(false);
      console.log(error);
      toast({
        description: "Error, contacte al administrador del servidor.",
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleRegister}
    >
      {(formik) => (
        <Form>
          <Field name="nombre">
            {({ field, form }) => (
              <FormControl isRequired mb={2} isInvalid={form.errors.nombre}>
                <FormLabel>Nombre Completo</FormLabel>
                <Input {...field} onChange={(e) => handleNameChange(e, form)} />
                <ErrorMessage name="nombre" />
              </FormControl>
            )}
          </Field>

          <Field name="telefono">
            {({ field, form }) => (
              <FormControl mb={2} isRequired isInvalid={form.errors.telefono}>
                <FormLabel>Teléfono</FormLabel>
                <Input type="number" {...field} />
                <ErrorMessage name="telefono" />
              </FormControl>
            )}
          </Field>

          <Field name="correo">
            {({ field, form }) => (
              <FormControl isRequired mb={2} isInvalid={form.errors.correo}>
                <FormLabel>Correo Electrónico</FormLabel>
                <Input {...field} />
                <ErrorMessage name="correo" />
              </FormControl>
            )}
          </Field>

          <Field name="password">
            {({ field, form }) => (
              <FormControl
                id="password"
                isRequired
                mb={2}
                isInvalid={form.errors.password}
              >
                <FormLabel>Contraseña</FormLabel>
                <InputGroup>
                  <Input {...field} type={showPassword ? "text" : "password"} />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      onClick={handlePasswordVisibility}
                    >
                      {showPassword ? (
                        <Icon as={FaEye} />
                      ) : (
                        <Icon as={FaEyeSlash} />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <ErrorMessage name="password" />
              </FormControl>
            )}
          </Field>

          <Field name="password1">
            {({ field, form }) => (
              <FormControl
                id="password1"
                mb={2}
                isInvalid={form.errors.password1}
              >
                <FormLabel>Confirmar Contraseña</FormLabel>
                <InputGroup>
                  <Input {...field} type={showPassword ? "text" : "password"} />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      onClick={handlePasswordVisibility}
                    >
                      {showPassword ? (
                        <Icon as={FaEye} />
                      ) : (
                        <Icon as={FaEyeSlash} />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <ErrorMessage name="password1" />
              </FormControl>
            )}
          </Field>

          <Stack spacing={10} pt={2}>
            <Button
              bg="blue.400"
              color="white"
              isLoading={formik.isSubmitting}
              loadingText="Guardando información"
              mt={4}
              onClick={handleConfirmation}
              size="lg"
              _hover={{
                bg: "blue.500",
              }}
            >
              Finalizar registro
            </Button>
          </Stack>

          <Modal isOpen={isConfirmationOpen} onClose={handleCloseConfirmation}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Confirmación</ModalHeader>
              <ModalBody>
                ¿Estás seguro de que deseas finalizar el registro?
              </ModalBody>
              <ModalFooter>
                <Button
                  colorScheme="blue"
                  mr={3}
                  onClick={() => {
                    handleCloseConfirmation();
                    formik.handleSubmit();
                  }}
                >
                  Confirmar
                </Button>
                <Button variant="ghost" onClick={handleCloseConfirmation}>
                  Cancelar
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

import React, { useState } from "react";
import { Box, Button, Flex, Heading, Input, useToast } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import { registerLocale } from "react-datepicker";
import { format } from "date-fns";
import { RenderLoading } from "../RenderAlert";
import { useStore } from "../../hooks/useStore";
import { TablaReportsHistory } from "../tables/TablaReportsHistory";
import { MessageBox } from "../MessageBox";

registerLocale("es", es);

export const HistoryReportsModule = () => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const { reportsHistory, getReportsHistory } = useStore();

  const handleDateChange = (date) => {
    setIsOpen(!isOpen);
    setSelectedDate(date);
  };

  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const formatDateToSend = (date) => {
    return format(date, "yyyy-MM-dd"); // Formatea la fecha como 'yyyy-MM-dd'
  };

  const handleGetHistory = async () => {
    try {
      if (!selectedDate || inputValue.trim() === "") {
        return toast({
          description: "Seleccione la fecha y la dirección a consultar.",
          status: "info",
          position: "bottom-left",
          variant: "left-accent",
          duration: 4000,
          isClosable: true,
        });
      }

      const formattedDate = formatDateToSend(selectedDate);
      setIsLoading(true);
      await getReportsHistory(formattedDate, inputValue);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {isLoading && <RenderLoading state={isLoading} />}

      <Flex
        bg="white"
        borderRadius="lg"
        borderWidth={1}
        boxShadow="md"
        direction="column"
        justifyContent="space-between"
        mt={4}
        padding={4}
        w="100%"
      >
        <Flex w={"100%"} justifyContent={"space-between"} alignItems={"center"}>
          <Heading as="h2" size="lg" mb={4}>
            Historial por domicilio
          </Heading>
        </Flex>

        <Flex position="relative" mb={2}>
          <Button mr={2} onClick={handleClick}>
            {format(selectedDate, "yyyy-MM-dd")}
          </Button>
          <Input
            mr={2}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Escribe la dirección"
            value={inputValue}
            width="200px"
          />
          <Box
            position="absolute"
            top="calc(100% + 10px)"
            left="0"
            zIndex="999"
          >
            {isOpen && (
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                inline
                maxDate={new Date()}
                onClickOutside={handleClick}
                locale="es"
              />
            )}
          </Box>

          <Button
            onClick={handleGetHistory}
            justifySelf="flex-end"
            colorScheme="twitter"
          >
            Buscar Historial
          </Button>
        </Flex>

        {reportsHistory?.length > 0 ? (
          <TablaReportsHistory history={reportsHistory} />
        ) : (
            <Flex justify='center'>

          <MessageBox
            message="No hay historial que visualizar"
            description="Seleccione una fecha y dirección."
            status="info"
            />
            </Flex>
        )}
      </Flex>
    </>
  );
};

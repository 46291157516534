import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { AuthState } from "./context/auth/AuthState";
import { AppRouter } from "./routers/AppRouter";
import "./assets/css/App.css";

function App() {
  return (
    <ChakraProvider>
      <AuthState>
        <AppRouter />
      </AuthState>
    </ChakraProvider>
  );
}
export default App;

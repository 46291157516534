import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa6";
import { useStore } from "../../hooks/useStore";

const initialValues = {
  placa: "",
  marca: "",
  modelo: "",
  color: "",
  comentario: "",
};

const validationSchema = Yup.object().shape({
  placa: Yup.string().required("Campo es obligatorio"),
  marca: Yup.string().required("Campo es obligatorio"),
  modelo: Yup.string().required("Campo es obligatorio"),
  color: Yup.string().required("Campo es obligatorio"),
});

export const RegisterVehicleForm = ({ direccion, nombre }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { registerResidentVehicle, handleError } = useStore();

  const handleRegister = async (values, { setSubmitting, resetForm }) => {
    try {
      setSubmitting(true);
      const data = { ...values, direccion, nombre };
      await registerResidentVehicle(nombre, data);
      setSubmitting(false);
      resetForm(initialValues);
      onClose();
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <>
      <Tooltip hasArrow label="Registrar vehiculo">
        <IconButton
          aria-label="registrar vehiculo"
          bg="white"
          boxShadow="md"
          icon={<FaPlus />}
          onClick={onOpen}
          size="lg"
          variant="filled"
        />
      </Tooltip>

      <Modal
        isCentered
        isOpen={isOpen}
        motionPreset="slideInBottom"
        onClose={onClose}
        scrollBehavior="inside"
        size="lg"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <RegisterForm
              initialValues={initialValues}
              onSubmit={handleRegister}
              onClose={onClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const RegisterForm = ({ initialValues, onSubmit, onClose }) => {
  const formatToCapitalize = (text) => {
    return text
      ? text
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
      : "";
  };

  const formatToUpperCase = (text) => {
    return text ? text.toUpperCase() : "";
  };

  const handleSubmit = (values, actions) => {
    const formattedValues = {
      ...values,
      placa: formatToUpperCase(values.placa),
      marca: formatToCapitalize(values.marca),
      modelo: formatToCapitalize(values.modelo),
      color: formatToCapitalize(values.color),
    };
    onSubmit(formattedValues, actions);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form>
          <Stack
            spacing={4}
            w={"full"}
            maxW={"md"}
            bg="white"
            rounded={"xl"}
            boxShadow={"lg"}
            p={6}
            my={6}
          >
            <Heading lineHeight={1.1} fontSize={{ base: "xl", sm: "2xl" }}>
              Registrar nuevo vehículo
            </Heading>

            <Field name="placa">
              {({ field, form }) => (
                <FormControl
                  id="placa"
                  isInvalid={form.errors.placa && form.touched.placa}
                >
                  <FormLabel>Placa*</FormLabel>
                  <Input
                    {...field}
                    // placeholder="Nombre del visitante"
                    type="text"
                    value={formatToUpperCase(field.value)}
                  />
                  <FormErrorMessage>{form.errors.placa}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="marca">
              {({ field, form }) => (
                <FormControl
                  id="marca"
                  isInvalid={form.errors.marca && form.touched.marca}
                >
                  <FormLabel>Marca*</FormLabel>
                  <Input
                    {...field}
                    // placeholder="Nombre del visitante"
                    type="text"
                    value={formatToCapitalize(field.value)}
                  />
                  <FormErrorMessage>{form.errors.marca}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="modelo">
              {({ field, form }) => (
                <FormControl
                  id="modelo"
                  isInvalid={form.errors.modelo && form.touched.modelo}
                >
                  <FormLabel>Modelo*</FormLabel>
                  <Input
                    {...field}
                    // placeholder="Nombre del visitante"
                    type="text"
                    value={formatToCapitalize(field.value)}
                  />
                  <FormErrorMessage>{form.errors.modelo}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="color">
              {({ field, form }) => (
                <FormControl
                  id="color"
                  isInvalid={form.errors.color && form.touched.color}
                >
                  <FormLabel>Color*</FormLabel>
                  <Input
                    {...field}
                    // placeholder="Nombre del visitante"
                    type="text"
                    value={formatToCapitalize(field.value)}
                  />
                  <FormErrorMessage>{form.errors.color}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Text fontWeight={"500"}>Comentarios</Text>
            <Field name="comentario">
              {({ field }) => (
                <Textarea {...field} label="Comentarios" name="comentario" />
              )}
            </Field>

            <Stack spacing={6} direction={["column", "row"]}>
              <Button
                bg={"blue.400"}
                color={"white"}
                w="full"
                type="submit"
                isLoading={formik.isSubmitting}
                _hover={{
                  bg: "blue.500",
                }}
              >
                Registrar
              </Button>
              <Button
                bg={"red.400"}
                color={"white"}
                w="full"
                type="button"
                onClick={() => {
                  formik.handleReset();
                  onClose();
                }}
                _hover={{
                  bg: "red.500",
                }}
              >
                Cancelar
              </Button>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

import React, { useState } from "react";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa6";

const validationSchema = Yup.object().shape({
  password: Yup.string().required("Campo es obligatorio"),
  password1: Yup.string()
    .oneOf([Yup.ref("password"), null], "Las contraseñas no coinciden")
    .required("Campo es obligatorio"),
});

const initialValues = {
  password: "",
  password1: "",
};

export const ChangePasswordScreen = () => {
  const navigate = useNavigate();
  const { updateUserPassword } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      setSubmitting(true);
      await updateUserPassword({ password: values.password });
      setSubmitting(false);
      resetForm(initialValues);
      navigate("/auth/login", { replace: true });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Flex minH="100vh" align="center" justify="center">
        <Stack w="md" maxW="lg" py={12} px={4}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form>
                <Stack
                  spacing={4}
                  w={"full"}
                  maxW={"md"}
                  bg="white"
                  rounded={"xl"}
                  boxShadow={"lg"}
                  p={6}
                  my={12}
                >
                  <Heading
                    lineHeight={1.1}
                    fontSize={{ base: "xl", sm: "2xl" }}
                  >
                    Crea una nueva contraseña
                  </Heading>

                  <Field name="password">
                    {({ field, form }) => (
                      <FormControl
                        id="password"
                        isRequired
                        mb={2}
                        isInvalid={form.errors.password}
                      >
                        <FormLabel>Contraseña</FormLabel>
                        <InputGroup>
                          <Input
                            {...field}
                            type={showPassword ? "text" : "password"}
                          />
                          <InputRightElement width="4.5rem">
                            <Button
                              h="1.75rem"
                              size="sm"
                              onClick={handlePasswordVisibility}
                            >
                              {showPassword ? (
                                <Icon as={FaEye} />
                              ) : (
                                <Icon as={FaEyeSlash} />
                              )}
                            </Button>
                          </InputRightElement>
                        </InputGroup>
                        <ErrorMessage name="password" />
                      </FormControl>
                    )}
                  </Field>

                  <Field name="password1">
                    {({ field, form }) => (
                      <FormControl
                        id="password1"
                        mb={2}
                        isInvalid={form.errors.password1}
                      >
                        <FormLabel>Confirmar Contraseña</FormLabel>
                        <InputGroup>
                          <Input
                            {...field}
                            type={showPassword ? "text" : "password"}
                          />
                          <InputRightElement width="4.5rem">
                            <Button
                              h="1.75rem"
                              size="sm"
                              onClick={handlePasswordVisibility}
                            >
                              {showPassword ? (
                                <Icon as={FaEye} />
                              ) : (
                                <Icon as={FaEyeSlash} />
                              )}
                            </Button>
                          </InputRightElement>
                        </InputGroup>
                        <ErrorMessage name="password1" />
                      </FormControl>
                    )}
                  </Field>

                  <Stack spacing={6} direction={["column", "row"]}>
                    <Button
                      bg={"blue.400"}
                      color={"white"}
                      w="full"
                      onClick={onOpen}
                      isLoading={formik.isSubmitting}
                      _hover={{
                        bg: "blue.500",
                      }}
                    >
                      Finalizar
                    </Button>
                  </Stack>
                </Stack>

                <Modal isOpen={isOpen} onClose={onClose}>
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>Confirmación</ModalHeader>
                    <ModalBody>
                      <Text>¿Estás seguro de cambiar la contraseña?</Text>
                      <Stack
                        spacing={4}
                        mt={4}
                        direction={["column", "row"]}
                        align="center"
                        justify="flex-end"
                      >
                        <Button variant="outline" onClick={onClose}>
                          Cancelar
                        </Button>
                        <Button
                          colorScheme="blue"
                          onClick={() => {
                            onClose();
                            handleSubmit(formik.values, formik);
                          }}
                        >
                          Confirmar
                        </Button>
                      </Stack>
                    </ModalBody>
                  </ModalContent>
                </Modal>
              </Form>
            )}
          </Formik>
        </Stack>
      </Flex>
    </>
  );
};

import React from "react";
import { Box, useBreakpointValue } from "@chakra-ui/react";
import { Routes, Route } from "react-router-dom";
// import { HomeScreen } from "../screens/HomeScreen";
import { Sidebar } from "../components/navbar/Sidebar";
import { SecurityScreen } from "../screens/admin/SecurityScreen";
import { CasetaScreen } from "../screens/CasetaScreen";
import { ProfileScreen } from "../screens/ProfileScreen";
import { VisitsScreen } from "../screens/VisitsScreen";
import { UserHistoryScreen } from "../screens/UserHistoryScreen";
import { ContributionsScreen } from "../screens/ContributionsScreen";
import { Dashboard } from "../screens/admin/Dashboard";
import { ExpensesScreen } from "../screens/admin/ExpensesScreen";
import { ReportsScreen } from "../screens/admin/ReportsScreen";
import { ContributionsAdminScreen } from "../screens/admin/ContributionsAdminScreen";

/*
  Router para todas las vistas internas de la plataforma con su path correspondiente.
  Si se desea agregar una nueva vista a la aplicacion, se debe ingresar como una ruta aca primero
*/

const smMarginSize = "0";
const mdMarginSize = "0rem";

const HomeRouter = () => {
  const marginSize = useBreakpointValue({
    base: smMarginSize,
    md: mdMarginSize,
  });
  return (
    <Box>
      <Sidebar>
        <Box marginLeft={marginSize} className="contenedor__home--main">
          <Routes>
            {/* <Route path="/" element={<ProfileScreen />} /> */}
            <Route path="/residente/resumen" element={<ProfileScreen />} />
            <Route path="/mis-visitas" element={<VisitsScreen />} />
            <Route path="/historial" element={<UserHistoryScreen />} />
            {/* <Route path="/Home" element={<HomeScreen />} /> */}

            <Route path="/caseta" element={<CasetaScreen />} />
            <Route path="/aportaciones" element={<ContributionsScreen />} />

            <Route path="/administracion/dashboard" element={<Dashboard />} />
            <Route path="/administracion/aportaciones" element={<ContributionsAdminScreen />} />
            <Route path="/administracion/gastos" element={<ExpensesScreen />} />
            <Route path="/administracion/reportes" element={<ReportsScreen />} />
            <Route path="/administracion/seguridad" element={<SecurityScreen />} />
          </Routes>
        </Box>
      </Sidebar>
    </Box>
  );
};

export default HomeRouter;

import React from "react";
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

export const TablaUserHistory = ({ userHistory }) => {
  return (
    <>
      {userHistory?.length > 0 && (
        <TableContainer borderRadius={10} shadow={"md"}>
          <Table variant="simple" bg={"white"}>
            <Thead bg="cyan.400">
              <Tr>
                <Th>Usuario</Th>
                <Th>Guardia</Th>
                <Th>Visitante</Th>
                <Th>Actividad</Th>
                <Th>Comentario</Th>
              </Tr>
            </Thead>
            <Tbody>
              {userHistory.map((value, index) => (
                <Tr key={index}>
                  <Td>{value?.idResidente}</Td>
                  <Td>{value?.idAdmin}</Td>
                  <Td>{value?.nombre}</Td>
                  <Td>{value?.actividad}</Td>
                  <Td noOfLines={1}>
                    {value?.comentarios ? value.comentarios : "Sin comentarios"}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

/* 
    maneja todas las funciones del authcontext que engloba todas las acciones de logueo o registro
*/

import React, { useEffect, useReducer } from "react";
import { useToast } from "@chakra-ui/react";
import { AuthContext } from "./AuthContext";
import AuthReducer from "./AuthReducer";
import { API } from "../../api/config";
import { setAuthToken } from "../../service/AuthService";

export const AuthState = ({ children }) => {
  const toast = useToast();
  const initialState = {
    isAuthenticated: Boolean(sessionStorage.getItem("isAuthenticated")),
    isLoginLoading: false,
    rol: JSON.parse(localStorage.getItem("rol")) || {},
    token: localStorage.getItem("token"),
    userDataRecovery: {},
    usuario: JSON.parse(localStorage.getItem("user")) || {},
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  useEffect(() => {
    loadUserData();
  }, []);

  useEffect(() => {
    if (state.token) {
      setAuthToken(state.token); // Configurar el token al inicio
    }
  }, [state.token]);

  const loadUserData = () => {
    const storedUsuario = JSON.parse(localStorage.getItem("user"));
    const storedRol = JSON.parse(localStorage.getItem("rol"));

    if (storedUsuario && storedRol) {
      dispatch({
        type: "LOAD_USER_DATA",
        payload: {
          usuario: storedUsuario,
          rol: storedRol,
        },
      });
    }
  };

  const startLogin = async (correo, password) => {
    try {
      const dataRequest = { correo, password };
      const url = "login/residentes";
      const { data } = await API.post(url, dataRequest);

      localStorage.setItem("user", JSON.stringify(data));
      localStorage.setItem("rol", JSON.stringify(data.idRol));
      localStorage.setItem("token", data.token);
      sessionStorage.setItem("isAuthenticated", true);

      dispatch({
        type: "LOGIN",
        payload: data,
      });
    } catch (error) {
      handleError(error);
    }
  };

  const logoutRequest = async () => {
    localStorage.clear();
    sessionStorage.clear();
    dispatch({
      type: "LOGOUT",
      payload: {},
    });
    setAuthToken(null);
  };

  const startLoading = (status) => {
    dispatch({
      type: "LOGIN_LOADING",
      payload: status,
    });
  };

  const validateUserData = async (formData) => {
    try {
      const url = "/login/recovery";
      const { data } = await API.post(url, formData);

      dispatch({
        type: "VALIDATE_USER_DATA",
        payload: data,
      });

      if (data.response) return true;
    } catch (error) {
      console.log(error);
      toast({
        description: "Los datos no son correctos.",
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const updateUserPassword = async (formData) => {
    try {
      const data = { ...formData, idResidente: state.userDataRecovery.id };
      const url = "/login/updatepswd";
      if (state.userDataRecovery.token) {
        const request = await API.put(url, data, {
          headers: {
            Authorization: `Bearer ${state.userDataRecovery.token}`,
          },
        });
        console.log(request);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleError = (error) => {
    dispatch({
      type: "LOGIN_LOADING",
      payload: false,
    });

    console.log(error);
    toast({
      description: "Correo o contraseña incorrecta.",
      status: "error",
      position: "bottom-left",
      variant: "left-accent",
      duration: 4000,
      isClosable: true,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        logoutRequest,
        startLoading,
        startLogin,
        updateUserPassword,
        validateUserData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

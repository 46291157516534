import React from "react";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object().shape({
  correo: Yup.string()
    .email("Debe ser un correo electrónico válido")
    .required("Campo es obligatorio"),
  direccion: Yup.string().required("Campo es obligatorio"),
  telefono: Yup.string()
    .matches(/^[0-9]+$/, "Solo se permiten números")
    .max(10, "El teléfono no debe tener más de 10 dígitos")
    .required("Campo es obligatorio"),
});

const initialValues = {
  correo: "",
  direccion: "",
  telefono: "",
};

export const CheckUserScreen = () => {
  const navigate = useNavigate();
  const { validateUserData } = useAuth();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const formData = { ...values, correo: values.correo.toLowerCase() };
      setSubmitting(true);
      const request = await validateUserData(formData);
      setSubmitting(false);
      resetForm(initialValues);

      if (request) {
        navigate("/auth/change-password", { replace: true });
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Flex minH="100vh" align="center" justify="center">
        <Stack w="md" maxW="lg" py={12} px={4}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form>
                <Stack
                  spacing={4}
                  w={"full"}
                  maxW={"md"}
                  bg="white"
                  rounded={"xl"}
                  boxShadow={"lg"}
                  p={6}
                  my={12}
                >
                  <Heading
                    lineHeight={1.1}
                    fontSize={{ base: "xl", sm: "2xl" }}
                  >
                    Valida tus datos
                  </Heading>

                  <Field name="correo">
                    {({ field, form }) => (
                      <FormControl
                        id="correo"
                        isInvalid={form.errors.correo && form.touched.correo}
                      >
                        <FormLabel>Correo electrónico</FormLabel>
                        <Input {...field} type="email" />
                        <FormErrorMessage>
                          {form.errors.correo}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="direccion">
                    {({ field, form }) => (
                      <FormControl
                        id="direccion"
                        isInvalid={
                          form.errors.direccion && form.touched.direccion
                        }
                      >
                        <FormLabel>Dirección</FormLabel>
                        <Input {...field} type="text" />
                        <FormErrorMessage>
                          {form.errors.direccion}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="telefono">
                    {({ field, form }) => (
                      <FormControl
                        id="telefono"
                        isInvalid={
                          form.errors.telefono && form.touched.telefono
                        }
                      >
                        <FormLabel>Teléfono</FormLabel>
                        <Input {...field} type="tel" />
                        <FormErrorMessage>
                          {form.errors.telefono}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Stack spacing={6} direction={["column", "row"]}>
                    <Button
                      bg={"blue.400"}
                      color={"white"}
                      w="full"
                      type="submit"
                      isLoading={formik.isSubmitting}
                      _hover={{
                        bg: "blue.500",
                      }}
                    >
                      Validar
                    </Button>
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>
        </Stack>
      </Flex>
    </>
  );
};

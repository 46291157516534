import React from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
} from "@chakra-ui/react";

export const MessageBox = ({ message, description, status = "success" }) => {
  return (
    <Box paddingX={5} w={{base: "100%", sm: "60%"}}>
      <Alert
        alignItems="center"
        borderRadius="lg"
        flexDirection="column"
        height={{base:"120px" ,md:"160px"}}
        justifyContent="center"
        status={status}
        textAlign="center"
        variant="subtle"
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          {message}
        </AlertTitle>
        {description && (
          <AlertDescription maxWidth="sm">{description}</AlertDescription>
        )}
      </Alert>
    </Box>
  );
};

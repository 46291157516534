/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Flex,
  Heading,
  ScaleFade,
  SimpleGrid,
  Stack,
} from "@chakra-ui/react";
import { CardWithIcon } from "../components/Cards";
import { FaCalendar, FaDollarSign } from "react-icons/fa6";
import { ServiceVisitModule } from "../components/modules/ServiceVisitModule";
import { ResidentContributionsModule } from "../components/modules/ResidentContributionsModule";
import { RecentVisitsModule } from "../components/modules/RecentVisitsModule";
import { useAuth } from "../hooks/useAuth";
import { RenderLoading } from "../components/RenderAlert";
import { useStore } from "../hooks/useStore";
import { MessageBox } from "../components/MessageBox";

export const ProfileScreen = () => {
  const { usuario } = useAuth();
  const {
    contributionsInfo,
    dashboardInfo,
    getInfoContributions,
    getInfoDashboard,
    getStatusResident,
    statusResident,
  } = useStore();
  const [isLoading, setisLoading] = useState(false);

  const { appor, bills } = contributionsInfo;
  const { aport, countVisit, visits } = dashboardInfo;

  useEffect(() => {
    const fetchResidentInfo = async () => {
      if (usuario.dir) {
        setisLoading(true);
        await getInfoDashboard(usuario.dir);
        await getStatusResident(usuario.dir);
        await getInfoContributions();
        setisLoading(false);
      }
    };

    fetchResidentInfo();
  }, []);

  return (
    <>
      {isLoading ? (
        <RenderLoading state={isLoading} />
      ) : (
        <ScaleFade initialScale={0.9} in={true}>
          <Box className="neumorphism--container" padding={5} bg={"white"}>
            <Stack spacing={4} as={Container} maxW={"3xl"} textAlign={"center"}>
              <Heading
                fontSize={{ base: "2xl", sm: "4xl" }}
                fontWeight={"bold"}
              >
                {usuario.dir} 
              </Heading>
            </Stack>

            {!statusResident && (
              <Flex justify={"center"} mt={10}>
                <MessageBox
                  message="Tienes que acudir a caseta a recibir tu visita."
                  status="warning"
                  description={
                    "Apoya con tu aportación mensual, es importante para el mantenimiento y seguridad de tu privada."
                  }
                />
              </Flex>
            )}

            <Container maxW={"full"} mt={10}>
              <Flex flexWrap="wrap" gridGap={4} justify="center">
                <CardWithIcon
                  icon={FaCalendar}
                  iconColor="blue.600"
                  iconBg="blue.200"
                  title={countVisit}
                  description={"Visitas en el mes"}
                />

                <CardWithIcon
                  icon={FaDollarSign}
                  iconColor="yellow.600"
                  iconBg="yellow.200"
                  title={appor}
                  description={"Aportaciones"}
                />

                <CardWithIcon
                  icon={FaDollarSign}
                  iconColor="red.600"
                  iconBg="red.200"
                  title={bills}
                  description={"Gastos"}
                />
              </Flex>
            </Container>

            <SimpleGrid columns={[1, 1, 2]} spacing={4} mt={4}>
              <RecentVisitsModule visits={visits} />
              <ServiceVisitModule />
            </SimpleGrid>

            <ResidentContributionsModule contributions={aport} />
          </Box>
        </ScaleFade>
      )}
    </>
  );
};

import React, { useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Divider,
  Flex,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { API } from "../../api/config";
import { MessageBox } from "../MessageBox";
import { useAuth } from "../../hooks/useAuth";

export const AuthorizedVehiclesModule = ({
  getAuthorizedItems,
  resetComponent,
  statusResident,
  vehicles,
}) => {
  const { usuario } = useAuth();
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionButtonClick = (index) => {
    if (index === activeIndex) {
      // Si el mismo índice se hace clic de nuevo, cierra el elemento.
      handleAccordionItemToggle(null); // Cierra el elemento
    } else {
      handleAccordionItemToggle(index); // Abre el elemento
    }
  };

  const handleAccordionItemToggle = (index) => {
    setActiveIndex(index);
  };

  return (
    <>
      {!statusResident && (
        <Flex justify="center" my={4}>
          <MessageBox
            message="Residente con falta de aportación."
            status="error"
            description={"Debe acudir a caseta para recibir su visita."}
          />
        </Flex>
      )}
      <Accordion allowToggle>
        {vehicles?.map((vehicle, index) => (
          <AccordionItem
            key={vehicle.idVehiculo}
            isExpanded={index === activeIndex}
          >
            <Heading>
              <AccordionButton
                onClick={() => handleAccordionButtonClick(index)}
                backgroundColor={
                  activeIndex === index ? "blue.400" : "transparent"
                }
                color={activeIndex === index ? "white" : "black"}
                _hover={{ backgroundColor: "blue.200" }}
              >
                <Box flex="1" textAlign="left">
                  {vehicle.placa
                    ? `Placa: ${vehicle?.placa.toUpperCase()} / ${
                        vehicle?.idTipo
                          ? vehicle?.idTipo
                          : "No especifica tipo de visita"
                      } / ${
                        vehicle?.comentario
                          ? vehicle?.comentario
                          : "Sin comentarios"
                      }`
                    : `Nombre: ${vehicle.nombre} / ${
                        vehicle?.idTipo
                          ? vehicle?.idTipo
                          : "No especifica tipo de visita"
                      } / ${
                        vehicle?.comentario
                          ? vehicle?.comentario
                          : "Sin comentarios"
                      }`}
                </Box>
              </AccordionButton>
            </Heading>
            <AccordionPanel>
              {vehicle.placa ? (
                <RegisteredVisit
                  getAuthorizedItems={getAuthorizedItems}
                  resetComponent={resetComponent}
                  user={usuario.nombre}
                  vehicle={vehicle}
                />
              ) : (
                <PreRegisteredVisit
                  getAuthorizedItems={getAuthorizedItems}
                  resetComponent={resetComponent}
                  user={usuario.nombre}
                  vehicle={vehicle}
                />
              )}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </>
  );
};

const RegisteredVisit = ({
  getAuthorizedItems,
  resetComponent,
  user,
  vehicle,
}) => {
  const toast = useToast();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ocupantes, setOcupantes] = useState(1);
  const [comentario, setComentario] = useState("Sin comentarios");

  const handleConfirm = async () => {
    try {
      const url = `/caseta/activity?id=${vehicle.idVehiculo}&process=1&usuario=${user}&ocupantes=${ocupantes}&comentario=${comentario}`;
      await API.put(url, vehicle);
      await getAuthorizedItems();
      setIsModalOpen(false);

      resetComponent();
      toast({
        title: "Acción completada",
        description: "El vehículo ha obtenido acceso con éxito.",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Card variant={"outline"} boxShadow="md">
        <CardBody>
          <Heading size="md">Información del Vehículo</Heading>
          <Text mt={2} fontSize="lg">
            Placa: {vehicle.placa}
          </Text>
          <Text fontSize="lg">Marca: {vehicle.marca}</Text>
          <Text fontSize="lg">Modelo: {vehicle.modelo}</Text>
          <Text fontSize="lg">Color: {vehicle.color}</Text>
          <Text fontSize="lg">Dirección: {vehicle.direccion}</Text>
          <Text fontSize="lg">Nombre: {vehicle.nombre}</Text>

          {/* Divider */}
          <Divider my={4} />

          {/* Input para el número de ocupantes */}
          <Flex direction="column" my={2}>
            <Text fontSize="lg">Ocupantes:</Text>
            <Input
              type="number"
              value={ocupantes}
              onChange={(e) => setOcupantes(e.target.value)}
            />
          </Flex>

          {/* Input para comentarios */}
          <Flex direction="column" my={2}>
            <Text fontSize="lg">Comentarios:</Text>
            <Textarea
              value={comentario}
              onChange={(e) => setComentario(e.target.value)}
            />
          </Flex>
        </CardBody>
        <CardFooter alignSelf={"flex-end"}>
          <Button colorScheme="blue" onClick={() => setIsModalOpen(true)}>
            Dar Acceso
          </Button>
        </CardFooter>
      </Card>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>¿Confirmar acción?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            ¿Está seguro de que desea dar acceso a este vehículo?
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleConfirm}>
              Confirmar
            </Button>
            <Button variant="ghost" onClick={() => setIsModalOpen(false)}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

// Nuevo componente para el formulario de ingreso de nuevo vehículo
const PreRegisteredVisit = ({
  getAuthorizedItems,
  resetComponent,
  user,
  vehicle,
}) => {
  const toast = useToast();
  const [placa, setPlaca] = useState("");
  const [marca, setMarca] = useState("");
  const [modelo, setModelo] = useState("");
  const [color, setColor] = useState("");
  const [ocupantes, setOcupantes] = useState(1);
  const [comentario, setComentario] = useState("Sin comentarios");

  const handleAddNewVehicle = async () => {
    const data = {
      placa,
      marca,
      modelo,
      color,
      ocupantes,
      comentario,
    };
    const url = `/caseta/activity?id=${vehicle.idVehiculo}&process=1&usuario=${user}&ocupantes=${ocupantes}&comentario=${comentario}`;
    await API.put(url, data);
    await getAuthorizedItems();

    resetComponent();

    setPlaca("");
    setMarca("");
    setModelo("");
    setColor("");
    setOcupantes("");
    setComentario("");

    toast({
      title: "Nuevo vehículo agregado",
      description: "El vehículo se ha agregado con éxito.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  return (
    <Card variant={"outline"} boxShadow="md">
      <CardBody>
        <Heading size="md">Ingresar Nuevo Vehículo</Heading>
        <Flex direction="column" mt={2}>
          <Text fontSize="lg">Direccion: {vehicle.direccion}</Text>
          <Text fontSize="lg">Nombre: {vehicle.nombre}</Text>
          <Text fontSize="lg">Placa:</Text>
          <Input
            value={placa}
            onChange={(e) => setPlaca(e.target.value)}
            placeholder="Placa del vehículo"
          />
        </Flex>
        <Flex direction="column" mt={2}>
          <Text fontSize="lg">Marca:</Text>
          <Input
            value={marca}
            onChange={(e) => setMarca(e.target.value)}
            placeholder="Marca del vehículo"
          />
        </Flex>
        <Flex direction="column" mt={2}>
          <Text fontSize="lg">Modelo:</Text>
          <Input
            value={modelo}
            onChange={(e) => setModelo(e.target.value)}
            placeholder="Modelo del vehículo"
          />
        </Flex>
        <Flex direction="column" mt={2}>
          <Text fontSize="lg">Color:</Text>
          <Input
            value={color}
            onChange={(e) => setColor(e.target.value)}
            placeholder="Color del vehículo"
          />
        </Flex>
        <Flex direction="column" mt={2}>
          <Text fontSize="lg">Ocupantes:</Text>
          <Input
            type="number"
            value={ocupantes}
            onChange={(e) => setOcupantes(e.target.value)}
          />
        </Flex>
        <Flex direction="column" mt={2}>
          <Text fontSize="lg">Comentarios:</Text>
          <Textarea
            value={comentario}
            onChange={(e) => setComentario(e.target.value)}
          />
        </Flex>
      </CardBody>
      <CardFooter alignSelf={"flex-end"}>
        <Button colorScheme="blue" onClick={handleAddNewVehicle}>
          Dar Acceso
        </Button>
      </CardFooter>
    </Card>
  );
};

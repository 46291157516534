/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, Container, Flex, Heading, ScaleFade } from "@chakra-ui/react";
import { RenderLoading } from "../../components/RenderAlert";
import { CardWithIcon } from "../../components/Cards";
import { FaArrowTrendDown, FaCalendar, FaDollarSign } from "react-icons/fa6";
import { useStore } from "../../hooks/useStore";
import { ContributionReportModule } from "../../components/modules/ContributionReportModule";

export const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const { contributionsInfo, getInfoContributions } = useStore();
  const { appor, bills, usercount } = contributionsInfo;

  useEffect(() => {
    const fetchInfo = async () => {
      setIsLoading(true);
      await getInfoContributions();
      setIsLoading(false);
    };

    fetchInfo();
  }, []);

  return (
    <>
      {isLoading ? (
        <RenderLoading state={isLoading} />
      ) : (
        <ScaleFade initialScale={0.9} in={true}>
          <Container maxW="full">
            <Heading mb={4}>Resumen</Heading>

            <Flex flexWrap="wrap" gridGap={4} justify="center">
              <CardWithIcon
                bg="white"
                icon={FaCalendar}
                iconColor="blue.600"
                iconBg="blue.200"
                title={usercount}
                description={"Usuarios activos"}
              />

              <CardWithIcon
                bg="white"
                icon={FaDollarSign}
                iconColor="green.600"
                iconBg="green.200"
                title={appor}
                description={"Aportaciones"}
              />

              <CardWithIcon
                bg="white"
                icon={FaArrowTrendDown}
                iconColor="red.600"
                iconBg="red.200"
                title={bills}
                description={"Gastos"}
              />
            </Flex>

            <Box mt={10}>
              <ContributionReportModule
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setIsLoading={setIsLoading}
              />
            </Box>
          </Container>
        </ScaleFade>
      )}
    </>
  );
};

export const GETS_VISITS = "GETS_VISITS";
export const GETS_VEHICLES_AUTHORIZED = "GETS_VEHICLES_AUTHORIZED";
export const GETS_RESIDENTIAL = "GETS_RESIDENTIAL";

export const GET_DASHBOARD_INFO = "GET_DASHBOARD_INFO";
export const GET_CONTRIBUTIONS_INFO = "GET_CONTRIBUTIONS_INFO";
export const GET_STATUS_RESIDENT = "GET_STATUS_RESIDENT";
export const GET_RESIDENT_HISTORY = "GET_RESIDENT_HISTORY"

export const GETS_ADDRESS_LIST = "GETS_ADDRESS_LIST";
export const GETS_EXPIRATION_LIST = "GETS_EXPIRATION_LIST";
export const GETS_EXPENSES_LIST = "GETS_EXPENSES_LIST";
export const GETS_CONTRIBUTIONS_LIST = "GETS_CONTRIBUTIONS_LIST";
export const GETS_SECURITY_HISTORY = "GETS_SECURITY_HISTORY";
export const GETS_REPORTS_HISTORY = "GETS_REPORTS_HISTORY";
export const GETS_GUARDS = "GETS_GUARDS";
export const GETS_DEFAULTERS = "GETS_DEFAULTERS";

import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Select,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { FaAngleLeft, FaAngleRight, FaTrash } from "react-icons/fa6";
import { ExpenseEditForm } from "../forms/ExpenseEditForm";
import { useStore } from "../../hooks/useStore";
import { RenderLoading } from "../RenderAlert";

export const TablaExpenses = ({ expenses, startDate, endDate }) => {
  const { putExpenseAdmin } = useStore();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = expenses?.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(expenses?.length / itemsPerPage);

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const renderPagination = () => {
    const adjacentPages = 2;
    const totalAdjacentPages = adjacentPages * 2 + 1;

    let startPage = Math.max(1, currentPage - adjacentPages);
    let endPage = Math.min(totalPages, startPage + totalAdjacentPages - 1);

    if (endPage - startPage < totalAdjacentPages - 1) {
      startPage = Math.max(1, endPage - totalAdjacentPages + 1);
    }

    return (
      <>
        <IconButton
          aria-label="pagina anterior"
          bg="white"
          borderRadius="full"
          icon={<Icon as={FaAngleLeft} />}
          onClick={() =>
            setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
          }
          variant="outline"
          isDisabled={currentPage === 1}
        />

        {pageNumbers.slice(startPage - 1, endPage).map((pageNumber) => (
          <Button
            key={pageNumber}
            bg={pageNumber === currentPage ? "blue.400" : "white"}
            borderRadius="full"
            color={pageNumber === currentPage ? "white" : "black"}
            mx={1}
            onClick={() => goToPage(pageNumber)}
            variant={pageNumber === currentPage ? "solid" : "outline"}
            width="30px"
            style={{
              fontWeight: pageNumber === currentPage ? "bold" : "normal",
            }}
          >
            {pageNumber}
          </Button>
        ))}

        <IconButton
          aria-label="pagina siguiente"
          bg="white"
          borderRadius="full"
          icon={<Icon as={FaAngleRight} />}
          variant="outline"
          isDisabled={currentPage === totalPages}
          onClick={() =>
            setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))
          }
        />
      </>
    );
  };

  // Cambiar a una página específica
  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (e) => {
    setCurrentPage(1);
    setItemsPerPage(parseInt(e.target.value, 10));
  };

  const handleDeleteClick = (expense) => {
    setSelectedExpense(expense);
    onOpen(); // Abre el modal de confirmación
  };

  const handleConfirmDelete = async () => {
    try {
      setIsLoading(true);
      await putExpenseAdmin(2, selectedExpense, startDate, endDate);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
    setSelectedExpense(null);
    onClose(); // Cierra el modal de confirmación
  };

  return (
    <>
      {isLoading && <RenderLoading state={isLoading} />}

      <Flex align="center" mb={4}>
        <Select
          onChange={handleItemsPerPageChange}
          value={itemsPerPage}
          bg="white"
          boxShadow={"md"}
          width="80px"
          mr={2}
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
        </Select>
        <Text>Elementos por página</Text>
      </Flex>

      {currentItems?.length > 0 && (
        <TableContainer borderRadius={10} boxShadow="md" bg="white">
          <Table variant="simple">
            <TableCaption>
              <Flex justify="space-between" align="center">
                {startDate &&
                  endDate ?
                  `Gastos entre el ${
                    startDate && format(startDate, "yyyy-MM-dd")
                  } y ${endDate && format(endDate, "yyyy-MM-dd")}` : "No hay rango de fecha seleccionado"}

                <Box>{renderPagination()}</Box>
              </Flex>
            </TableCaption>
            <Thead bg="cyan.400">
              <Tr>
                <Th>Folio</Th>
                <Th>Tipo de gasto</Th>
                <Th>Monto</Th>
                <Th>Fecha</Th>
                <Th>Acciones</Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentItems.map((value, index) => (
                <Tr key={index}>
                  <Td>{value?.folio.trim()}</Td>
                  <Td>{value?.tipoGasto}</Td>
                  <Td>{value?.cantidad}</Td>
                  <Td>
                    {value?.fecha &&
                      format(new Date(value?.fecha), "yyyy-MM-dd")}
                  </Td>
                  <Td>
                    <>
                      <ExpenseEditForm expense={value} startDate={startDate} endDate={endDate} />

                      <Tooltip hasArrow label="Eliminar gasto">
                        <IconButton
                          aria-label="eliminar gasto"
                          icon={<FaTrash />}
                          onClick={() => handleDeleteClick(value)}
                          size="lg"
                          variant="filled"
                        />
                      </Tooltip>
                    </>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody p={6}>
            <Text>¿Estás seguro de eliminar este gasto?</Text>
            <Flex justify="flex-end" mt={4}>
              <Button colorScheme="red" mr={2} onClick={handleConfirmDelete}>
                Confirmar
              </Button>
              <Button variant="outline" onClick={onClose}>
                Cancelar
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

import React from "react";
import {
  Card,
  CardBody,
  Flex,
  Heading,
  IconButton,
  List,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { FaEllipsisVertical, FaTrash } from "react-icons/fa6";
import { RegisterVehicleForm } from "../forms/RegisterVehicleForm";
import { MessageBox } from "../MessageBox";
import { useAuth } from "../../hooks/useAuth";
import { useStore } from "../../hooks/useStore";

export const RegisterVehicleModule = ({ vehicles }) => {
  const { usuario } = useAuth();
  const { handleError, putResidentVisit } = useStore();
  const { dir: direccion, nombre } = usuario;

  const handleDelete = async (id) => {
    try {
      await putResidentVisit(5, id, nombre, direccion);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Flex
      alignItems="center"
      bg="#edf2f7"
      borderRadius="lg"
      boxShadow="md"
      direction="column"
      mt={4}
      padding={4}
    >
      <Flex w={"100%"} justifyContent={"space-between"} alignItems={"center"}>
        <Heading as="h2" size="lg">
          Vehículos
        </Heading>

        <RegisterVehicleForm direccion={direccion} nombre={nombre} />
      </Flex>

      {vehicles?.length > 0 ? (
        <List spacing={2} w={"full"} mt={4}>
          {vehicles.map((vehicle, index) => (
            <Card key={index} w={"full"} boxShadow={"md"}>
              <CardBody
                alignItems={"center"}
                borderColor={"green.400"}
                borderLeftWidth={8}
                borderRadius={"lg"}
                display={"flex"}
                justifyContent={"space-between"}
              >
                <Text fontSize={"lg"}>{vehicle.placa}</Text>
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    icon={<FaEllipsisVertical />}
                    variant="filled"
                  />
                  <MenuList>
                    <MenuItem
                      icon={<FaTrash />}
                      onClick={() => handleDelete(vehicle.idVehiculo)}
                    >
                      Eliminar
                    </MenuItem>
                  </MenuList>
                </Menu>
              </CardBody>
            </Card>
          ))}
        </List>
      ) : (
        <Flex width="100%" h="100%" justify="center" align="center">
          <MessageBox description="Sin registros asociado" status="info" />
        </Flex>
      )}
    </Flex>
  );
};

import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  useToast,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import { registerLocale } from "react-datepicker";
import { format } from "date-fns";
import { useStore } from "../../hooks/useStore";
import { TablaContributions } from "../tables/TablaContributions";
import { MessageBox } from "../MessageBox";

registerLocale("es", es);

export const ContributionReportModule = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  setIsLoading,
}) => {
  const toast = useToast();
  const [isOpen, setIsOpen] = useState(false);

  const { contributions, getContributionsByRange } = useStore();

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleDateRangeSubmit = async () => {
    if (startDate && endDate) {
      setIsOpen(!isOpen);
      setIsLoading(true);
      const formattedStartDate = format(startDate, "yyyy-MM-dd");
      const formattedEndDate = format(endDate, "yyyy-MM-dd");
      await getContributionsByRange(formattedStartDate, formattedEndDate);
      setIsLoading(false);
    } else {
      toast({
        description: "Selecciona ambas fechas.",
        status: "info",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };
  return (
    <>
      <Container maxW={"full"}>
        <Heading mb={4}>Reporte Aportaciones</Heading>
        <Flex position="relative" justify="space-between" mb={4}>
          <Button colorScheme="twitter" shadow="md" onClick={handleClick}>
            Seleccionar rango de fecha
          </Button>
          <Box
            position="absolute"
            top="calc(100% + 10px)"
            left="0"
            zIndex="999"
          >
            {isOpen && (
              <>
                <DatePicker
                  endDate={endDate}
                  inline
                  maxDate={new Date()}
                  onChange={onChange}
                  selected={startDate}
                  selectsRange
                  startDate={startDate}
                  locale="es"
                />

                <Button
                  colorScheme="facebook"
                  onClick={handleDateRangeSubmit}
                  mr={1}
                >
                  Obtener aportaciones
                </Button>
                <Button onClick={handleClick}>Cancelar</Button>
              </>
            )}
          </Box>
        </Flex>

        {contributions?.length > 0 ? (
          <TablaContributions
            contributions={contributions}
            startDate={startDate}
            endDate={endDate}
          />
        ) : (
          <Flex
            bg="white"
            borderRadius="lg"
            borderWidth={1}
            boxShadow="md"
            justify="center"
            mt={4}
            py={6}
            width="full"
          >
            <MessageBox
              message="Sin registro"
              description="Seleccione un rango de fecha."
              status="info"
            />
          </Flex>
        )}
      </Container>
    </>
  );
};

import React from "react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { FiMenu } from "react-icons/fi";
import { useAuth } from "../../hooks/useAuth";
import { FaChevronDown, FaPhone } from "react-icons/fa6";

const ROLES = {
  1: "Root",
  2: "Administrador",
  3: "Comité",
  4: "Guardia",
  7: "Residente",
};

export const MobileNav = ({ onOpen, ...rest }) => {
  const { usuario, rol, logoutRequest } = useAuth();

  const roleName = ROLES[rol] || "Sin rol";

  const handleCall = () => {
    window.location.href = "tel:+526867394704";
  };

  return (
    <Flex
      ml={{ base: 0, lg: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent={{ base: "space-between", lg: "flex-end" }}
      {...rest}
    >
      <IconButton
        display={{ base: "flex", lg: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text
        display={{ base: "flex", lg: "none" }}
        fontSize="2xl"
        fontFamily="Gugi"
        fontWeight="bold"
        textColor={"blue.600"}
      >
        Villalba Residencial
      </Text>

      <HStack spacing={{ base: "0", md: "6" }}>
        <Button
          leftIcon={<FaPhone />}
          variant="ghost"
          onClick={handleCall}
          display={{ base: "flex", md: "flex" }}
        >
          Caseta
        </Button>

        <Flex alignItems={"center"} pr={5}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack>
                <Avatar size={"sm"} />
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm">{usuario.nombre}</Text>
                  <Text fontSize="xs" color="gray.600">
                    {roleName}
                  </Text>
                </VStack>
                <Box display={{ base: "none", md: "flex" }}>
                  <FaChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue("white", "gray.900")}
              borderColor={useColorModeValue("gray.200", "gray.700")}
            >
              <MenuItem onClick={logoutRequest}>Cerrar sesión</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

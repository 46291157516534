/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  ScaleFade,
  useToast,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import { registerLocale } from "react-datepicker";
import { format } from "date-fns";
import { TablaExpenses } from "../../components/tables/TablaExpenses";
import { MessageBox } from "../../components/MessageBox";
import { ExpenseForm } from "../../components/forms/ExpenseForm";
import { RenderLoading } from "../../components/RenderAlert";
import { useStore } from "../../hooks/useStore";

registerLocale("es", es);

export const ExpensesScreen = () => {
  const toast = useToast();
  const { expenses, getExpensesByDateRange } = useStore();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleDateRangeSubmit = async () => {
    if (startDate && endDate) {
      setIsOpen(!isOpen);
      setIsLoading(true);
      const formattedStartDate = format(startDate, "yyyy-MM-dd");
      const formattedEndDate = format(endDate, "yyyy-MM-dd");
      await getExpensesByDateRange(formattedStartDate, formattedEndDate);
      setIsLoading(false);
    } else {
      toast({
        description: "Selecciona ambas fechas.",
        status: "info",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      {isLoading ? (
        <RenderLoading state={isLoading} />
      ) : (
        <ScaleFade initialScale={0.9} in={true}>
          <Heading mb={4}>Gastos Administrativos</Heading>

          <Container maxW={"full"}>
            <Flex position="relative" justify="space-between" mb={4}>
              <Button colorScheme="twitter" shadow="md" onClick={handleClick}>
                Seleccionar rango de fecha
              </Button>
              <Box
                position="absolute"
                top="calc(100% + 10px)"
                left="0"
                zIndex="999"
              >
                {isOpen && (
                  <>
                    <DatePicker
                      endDate={endDate}
                      inline
                      maxDate={new Date()}
                      onChange={onChange}
                      selected={startDate}
                      selectsRange
                      startDate={startDate}
                      locale="es"
                    />

                    <Button
                      colorScheme="facebook"
                      onClick={handleDateRangeSubmit}
                      mr={1}
                    >
                      Obtener gastos
                    </Button>
                    <Button onClick={handleClick}>Cancelar</Button>
                  </>
                )}
              </Box>

              <ExpenseForm startDate={startDate} endDate={endDate} />
            </Flex>

            {expenses?.length > 0 ? (
              <TablaExpenses
                expenses={expenses}
                startDate={startDate}
                endDate={endDate}
              />
            ) : (
              <Flex
                bg="white"
                borderRadius="lg"
                borderWidth={1}
                boxShadow="md"
                justify="center"
                mt={4}
                py={6}
                width="full"
              >
                <MessageBox
                message="Sin registro asociado"
                  description="Seleccione un rango de fecha."
                  status="info"
                />
              </Flex>
            )}
          </Container>
        </ScaleFade>
      )}
    </>
  );
};

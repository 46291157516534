/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ScaleFade,
} from "@chakra-ui/react";
import {
  FaArrowRotateRight,
  FaMagnifyingGlass,
  FaXmark,
} from "react-icons/fa6";
import { RenderLoading } from "../components/RenderAlert";
import { useDebounce } from "../hooks/useDebounce";
import { API } from "../api/config";
import { CardVehicle } from "../components/CardVehicle";
import { MessageBox } from "../components/MessageBox";
import { useStore } from "../hooks/useStore";
import { AuthorizedVehiclesModule } from "../components/modules/AuthorizedVehiclesModule";
import { useAuth } from "../hooks/useAuth";

export const CasetaScreen = () => {
  const { usuario } = useAuth();
  const {
    vehicles,
    statusResident,
    getsVehiclesAuthorized,
    getStatusResident,
  } = useStore();

  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSearchEmpty, setIsSearchEmpty] = useState(true);
  const [registeredVehicles, setRegisteredVehicles] = useState([]);
  const [vehicleIndex, setVehicleIndex] = useState(null);
  const [showAuthorizedVehicles, setShowAuthorizedVehicles] = useState(false);
  const [showMessageBox, setShowMessageBox] = useState(false);

  const handleRefresh = async () => {
    setIsLoading(true);
    await getsVehiclesAuthorized();
    setIsLoading(false);
  };

  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        setIsLoading(true);
        await getsVehiclesAuthorized();
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    fetchVehicles();
  }, []);

  const handleSearch = useDebounce(async (term) => {
    if (term.trim() === "") {
      setShowAuthorizedVehicles(false);
      setShowMessageBox(false);
      setIsSearchEmpty(true);
    } else {
      setIsSearchEmpty(false);
      try {
        const url = `/caseta/search?search=${term}`;
        const { data } = await API.get(url);

        if (data.response === false) {
          setShowMessageBox(true);
          setShowAuthorizedVehicles(false);
        } else {
          await getStatusResident(term);
          setRegisteredVehicles(data.resultado);
          setShowAuthorizedVehicles(true);
          setShowMessageBox(false);
        }
      } catch (error) {
        console.log(error);
        setShowAuthorizedVehicles(false);
        setShowMessageBox(false);
      }
    }
  }, 500);

  const handleChange = (event) => {
    const { value } = event.target;
    const upperCaseValue = value.toUpperCase();
    setSearch(upperCaseValue);

    // Debounce the search callback
    handleSearch(upperCaseValue);
  };

  const resetComponent = () => {
    setRegisteredVehicles({});
    setVehicleIndex(null);
    setShowAuthorizedVehicles(false);
    setIsModalOpen(false);
    setSearch("");
    setIsSearchEmpty(true);
  };

  const handleConfirmDarSalida = async () => {
    try {
      const url = `/caseta/out?id=${vehicleIndex}&usuario=${usuario.nombre}`;
      await API.put(url);
      await getsVehiclesAuthorized();
      setIsModalOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {isLoading ? (
        <RenderLoading state={isLoading} />
      ) : (
        <ScaleFade initialScale={0.9} in={true}>
          <Flex justifyContent="space-evenly" marginBottom={5}>
            <IconButton
              aria-label="Refresh"
              background="white"
              boxShadow="md"
              icon={<FaArrowRotateRight />}
              marginRight={5}
              onClick={handleRefresh}
              size="lg"
              variant="ghost"
            />

            <Flex width="100%">
              <Input
                background="white"
                borderRadius="none"
                borderLeftRadius={10}
                boxShadow="md"
                htmlSize={40}
                onChange={handleChange}
                placeholder="Buscar por placa o dirección"
                size="lg"
                value={search}
              />
              {isSearchEmpty ? (
                <IconButton
                  aria-label="Buscar"
                  bg="white"
                  borderRadius="none"
                  borderRightRadius={10}
                  boxShadow="md"
                  icon={<Icon as={FaMagnifyingGlass} />}
                  size="lg"
                  variant="solid"
                />
              ) : (
                <IconButton
                  aria-label="Limpiar buscador"
                  bg="white"
                  borderRadius="none"
                  borderRightRadius={10}
                  boxShadow="md"
                  icon={<Icon as={FaXmark} />}
                  size="lg"
                  variant="solid"
                  onClick={() => {
                    setSearch("");
                    setIsSearchEmpty(true);
                    setShowAuthorizedVehicles(false);
                    setShowMessageBox(false);
                  }}
                />
              )}
            </Flex>
          </Flex>
          <Box className="neumorphism--container" py={5} bg={"white"}>
            {showAuthorizedVehicles ? (
              <AuthorizedVehiclesModule
                getAuthorizedItems={getsVehiclesAuthorized}
                resetComponent={resetComponent}
                statusResident={statusResident}
                vehicles={registeredVehicles}
              />
            ) : showMessageBox ? (
              <Flex justify={"center"}>
                <MessageBox
                  message="Sin Coincidencias"
                  status="error"
                  description={
                    "Verifique que la dirección o la placa sea la correcta."
                  }
                />
              </Flex>
            ) : vehicles.length > 0 ? (
              <Container maxW={"full"}>
                <Flex flexWrap="wrap" gridGap={4} justify="center">
                  {vehicles.map((item, index) => (
                    <CardVehicle
                      key={index}
                      direccion={item.direccion}
                      estado={item.estado}
                      id={item.idVehiculo}
                      nombre={item.nombre}
                      placa={item.placa}
                      setIsModalOpen={setIsModalOpen}
                      setVehicleIndex={setVehicleIndex}
                    />
                  ))}
                </Flex>
              </Container>
            ) : (
              <Flex justify={"center"}>
                <MessageBox
                  status="info"
                  description={
                    "No hay visitas dentro del residencial para salida."
                  }
                />
              </Flex>
            )}
          </Box>
        </ScaleFade>
      )}

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>¿Confirmar acción?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            ¿Está seguro de que desea dar salida a este visitante?
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleConfirmDarSalida}>
              Confirmar
            </Button>
            <Button variant="ghost" onClick={() => setIsModalOpen(false)}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

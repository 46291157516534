import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { FaAngleLeft, FaAngleRight, FaPenToSquare } from "react-icons/fa6";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, parseISO } from "date-fns";
import es from "date-fns/locale/es";
import { MessageBox } from "../MessageBox";
import { useStore } from "../../hooks/useStore";
import { useAuth } from "../../hooks/useAuth";

const ConfirmationModal = ({ isOpen, onClose, onConfirmation }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirmar Cambio de Fecha</ModalHeader>
        <ModalBody>¿Está seguro de cambiar la fecha?</ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => onConfirmation(true)}
          >
            Confirmar
          </Button>
          <Button variant="ghost" onClick={() => onConfirmation(false)}>
            Cancelar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const DatepickerTable = ({ value }) => {
  const { usuario } = useAuth();
  const { putResidentExpiration } = useStore();

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isLoading, setisLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [newSelectedDate, setNewSelectedDate] = useState(null);

  useEffect(() => {
    if (value && value.vencimiento) {
      const parsedDate = parseISO(value.vencimiento);
      setSelectedDate(parsedDate);
    }
  }, [value]);

  const handleDateChange = (date) => {
    setNewSelectedDate(date);
    setShowConfirmationModal(true);
  };

  const handleConfirmation = async (confirmed) => {
    if (confirmed && newSelectedDate) {
      try {
        setisLoading(true);
        const dataUser = { ...value, vencimiento: newSelectedDate };
        await putResidentExpiration(usuario.nombre, dataUser);
        setisLoading(false);
        setSelectedDate(newSelectedDate);
      } catch (error) {
        // Manejo de errores
        console.error("Error al actualizar la fecha:", error);
      }
    }
    setShowConfirmationModal(false);
  };

  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <Flex position="relative" align="center">
      {format(selectedDate, "yyyy-MM-dd")}

      <Box
        bottom="0"
        marginBottom="10px"
        marginRight="10px"
        position="fixed"
        right="0"
        // transform="translate(-50%, -50%)"
        zIndex="1000"
      >
        {isOpen && (
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            inline
            onClickOutside={handleClick}
            locale={es}
          />
        )}
      </Box>

      <IconButton
        isLoading={isLoading}
        aria-label="datepicker"
        bg="white"
        icon={<Icon as={FaPenToSquare} />}
        variant="solid"
        onClick={handleClick}
        ml={2}
      />
      <ConfirmationModal
        isOpen={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        onConfirmation={handleConfirmation}
      />
    </Flex>
  );
};

export const TablaResidentExpiration = ({ address }) => {
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = address?.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(address?.length / itemsPerPage);

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const visibleElementsText = `Mostrando ${indexOfFirstItem + 1} - ${
    indexOfLastItem > address?.length ? address?.length : indexOfLastItem
  } de ${address?.length} elementos`;

  const renderPagination = () => {
    const adjacentPages = 2;
    const totalAdjacentPages = adjacentPages * 2 + 1;

    let startPage = Math.max(1, currentPage - adjacentPages);
    let endPage = Math.min(totalPages, startPage + totalAdjacentPages - 1);

    if (endPage - startPage < totalAdjacentPages - 1) {
      startPage = Math.max(1, endPage - totalAdjacentPages + 1);
    }

    return (
      <>
        {/* <Button onClick={() => goToPage(1)} disabled={currentPage === 1}>
          Primera
        </Button> */}
        <IconButton
          aria-label="pagina anterior"
          bg="white"
          borderRadius="full"
          icon={<Icon as={FaAngleLeft} />}
          onClick={() =>
            setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
          }
          variant="outline"
          isDisabled={currentPage === 1}
        />

        {pageNumbers.slice(startPage - 1, endPage).map((pageNumber) => (
          <Button
            key={pageNumber}
            bg={pageNumber === currentPage ? "blue.400" : "white"}
            borderRadius="full"
            color={pageNumber === currentPage ? "white" : "black"}
            mx={1}
            onClick={() => goToPage(pageNumber)}
            variant={pageNumber === currentPage ? "solid" : "outline"}
            width="30px"
            style={{
              fontWeight: pageNumber === currentPage ? "bold" : "normal",
            }}
          >
            {pageNumber}
          </Button>
        ))}

        <IconButton
          aria-label="pagina siguiente"
          bg="white"
          borderRadius="full"
          icon={<Icon as={FaAngleRight} />}
          variant="outline"
          isDisabled={currentPage === totalPages}
          onClick={() =>
            setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))
          }
        />

        {/* <Button
          onClick={() => goToPage(totalPages)}
          disabled={currentPage === totalPages}
        >
          Última
        </Button> */}
      </>
    );
  };

  // Cambiar a una página específica
  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (e) => {
    setCurrentPage(1);
    setItemsPerPage(parseInt(e.target.value, 10));
  };

  return (
    <>
      <Flex align="center" mb={4}>
        <Select
          onChange={handleItemsPerPageChange}
          value={itemsPerPage}
          bg="white"
          boxShadow={"md"}
          width="80px"
          mr={2}
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
        </Select>
        <Text>Elementos por página</Text>
      </Flex>

      {currentItems?.length > 0 ? (
        <TableContainer borderRadius={10} shadow={"md"} bg="white">
          <Table variant="simple">
            <TableCaption>
              <Flex justify="space-between" align="center">
                {visibleElementsText}

                <Box>{renderPagination()}</Box>
              </Flex>
            </TableCaption>
            <Thead bg="cyan.400">
              <Tr>
                <Th>Direccion</Th>
                <Th>Fecha de vencimiento</Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentItems.map((value, index) => (
                <Tr key={index}>
                  <Td>{value.idResidencia}</Td>
                  <Td>{<DatepickerTable value={value} />}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <MessageBox message="No hay registros asociados." status="info" />
      )}
    </>
  );
};

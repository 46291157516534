import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { FaCar, FaHouse, FaUser } from "react-icons/fa6";

export const CardVehicle = ({
  id,
  placa,
  direccion,
  nombre,
  estado,
  setVehicleIndex,
  setIsModalOpen,
}) => {
  return (
    <Box
      borderRadius="lg"
      borderWidth="1px"
      boxShadow='md'
      w={{base:"180px", sm: "200px", md: '240px'}}
      overflow="hidden"
      p={3}
    >
      <Stack
        direction='row'
        align="center"
      >
        <Flex
          align={"center"}
          bg={estado === 1 ? "yellow.200" : (estado === 4 || estado === 8 ? "blue.300" : (estado === 6 || estado === 7 ? "green.300" : ""))}
          color={"gray.600"}
          h={16}
          justify={"center"}
          padding={6}
          rounded={"md"}
          w={16}
        >
          <Icon as={FaCar} boxSize={8} />
        </Flex>

        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          mt={{ base: 2, md: 0 }}
          width={"100%"}
        >
          <Heading size="md">{placa}</Heading>

          <Flex mt={2} alignItems="center">
            <Icon as={FaUser} color="gray.500" boxSize={4} mr={2} />
            <Text fontSize={"sm"}>{nombre}</Text>
          </Flex>

          <Flex alignItems="center">
            <Icon as={FaHouse} color="gray.500" boxSize={4} mr={2} />
            <Text fontSize={"sm"}>{direccion}</Text>
          </Flex>

          <Button
            variant={"link"}
            colorScheme={"blue"}
            size={"sm"}
            alignSelf={"flex-end"}
            onClick={() => {
              setVehicleIndex(id);
              setIsModalOpen(true);
            }}
          >
            Dar Salida
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

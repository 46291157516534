import React from "react";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Link,
  Heading,
  Text,
  Button,
  Divider,
  AbsoluteCenter,
} from "@chakra-ui/react";
import { FaArrowRightToBracket } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useForm } from "../../hooks/useForm";
import { useAuth } from "../../hooks/useAuth";

export const LoginScreen = () => {
  const navigate = useNavigate();
  const { startLogin, isLoginLoading, startLoading } = useAuth();

  const [formValues, handleInputChange] = useForm({
    correo: "",
    password: "",
  });

  const { correo, password } = formValues;

  const handleLogin = (e) => {
    e.preventDefault();
    startLoading(true);
    startLogin(correo.toLowerCase().trim(), password.trim());
  };

  return (
    <>
      <Flex minH={"100vh"} align={"center"} justify={"center"}>
        <Stack mx={"auto"} w={"md"} maxW={"lg"} py={12} px={4}>
          <Box rounded={"lg"} boxShadow={"lg"} p={18}>
            <Heading
              textAlign={"center"}
              fontWeight={600}
              fontSize={"4xl"}
              lineHeight={"110%"}
            >
              <Text as={"span"} color={"cyan.500"}>
                Bienvenido Residente
              </Text>
              <br />
              <br />
            </Heading>
            <Stack spacing={4}>
              <form className="form__login--box">
                <FormControl mb={3}>
                  <FormLabel>Correo electrónico</FormLabel>
                  <Input
                    autoComplete="off"
                    borderColor="#a9a9a9"
                    className="form__login--input"
                    name="correo"
                    onChange={handleInputChange}
                    type="text"
                    value={correo}
                    _hover={{
                      borderColor: "#a9a9a9",
                    }}
                    _focus={{ borderColor: "#262626" }}
                  />
                </FormControl>

                <FormControl mb={2}>
                  <FormLabel>Contraseña</FormLabel>
                  <Input
                    borderColor="#a9a9a9"
                    className="form__login--input"
                    name="password"
                    onChange={handleInputChange}
                    type="password"
                    value={password}
                    _hover={{
                      borderColor: "#a9a9a9",
                    }}
                    _focus={{ borderColor: "#262626" }}
                  />
                </FormControl>

                <Flex justify="flex-end">
                  <Link
                    color="blue.500"
                    fontSize="sm"
                    onClick={() => {
                      navigate("/auth/check-user");
                    }}
                  >
                    ¿Has olvidado tu contraseña?
                  </Link>
                </Flex>

                <Button
                  colorScheme="twitter"
                  isLoading={isLoginLoading}
                  onClick={handleLogin}
                  rightIcon={<FaArrowRightToBracket />}
                  variant="solid"
                  w="full"
                  mt={2}
                >
                  Iniciar sesión
                </Button>
              </form>

              <Box position="relative" my={2}>
                <Divider borderWidth={1} borderColor="gray.300" />
                <AbsoluteCenter bg={"white"} p="2">
                  O
                </AbsoluteCenter>
              </Box>

              <Button
                onClick={() => {
                  navigate("/auth/validate-code");
                }}
                w="full"
              >
                Registrate aquí
              </Button>

              {/* <Button
                onClick={async () => {
                  console.log('clicked');
                  const res = await fetch("/api/send", {
                    method: "POST",
                  });
                  const data = await res.json();
                  console.log(data);
                }}
                w="full"
              >
                Enviame un correo
              </Button> */}

              {/* <Flex justify="center">
                <Text>
                  ¿No tienes una cuenta?{" "}
                  <Link
                    color="blue.500"
                    fontSize="sm"
                    onClick={() => {
                      navigate("/auth/validate-code");
                    }}
                  >
                    Regístrate aquí
                  </Link>
                </Text>
              </Flex> */}

              <div className="bg__login--img" />
            </Stack>
          </Box>
        </Stack>
      </Flex>
    </>
  );
};

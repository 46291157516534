/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Flex,
  Box,
  Stack,
  Text,
  useColorModeValue,
  Divider,
  Image,
  Heading,
} from "@chakra-ui/react";
import { RegisterNewUser } from "../../components/forms/RegisterNewUser";

const initialValues = {
  idRol: 7,
  nombre: "",
  direccion: "",
  correo: "",
  telefono: "",
};

export const RegisterScreen = () => {
  const [dataResidente, setDataResidente] = useState({});
  const [formData, setFormData] = useState(initialValues);

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("residente"));

    setDataResidente(data);
    if (data) {
      setFormData({
        ...formData,
        direccion: data.direccion?.trim(),
      });
    }
  }, []);

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack mx={"auto"} maxW={"2xl"} py={12} px={6}>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={4}
        >
          <Box
            w="100%"
            h="10rem"
            bg="blue.400"
            position="relative"
            overflow="hidden"
            borderRadius={"lg"}
          >
            <Image className="register-image" alt="Logo residencial" />
          </Box>
          <Stack as={Box} spacing={4} marginY={2}>
            <Heading
              textAlign={"center"}
              fontWeight={600}
              fontSize={"4xl"}
              lineHeight={"110%"}
            >
              Residencial Villalba <br />
              <Text as={"span"} color={"cyan.400"}>
                Bienvenido residente
              </Text>
            </Heading>
            <Text fontSize={"md"} color={"gray.600"}>
              Para mejorar su seguridad y comodidad, verifique todos sus datos
              antes de cerrar su registro, ya que este es único por RESIDENTE.
            </Text>
            <Text fontSize={"md"} color={"gray.600"}>
              Verifique la siguiente información.
            </Text>
            <Text fontSize={"md"} fontWeight={"semibold"} color={"gray.600"}>
              Usted es residente de: {dataResidente.direccion}
            </Text>
          </Stack>

          <Divider mt={4} mb={4} borderWidth={1} borderColor={"gray.300"} />

          <Stack spacing={4}>
            <RegisterNewUser
              idResidente={dataResidente.idResidencial}
              direccion={dataResidente.direccion}
            />

            <Stack pt={6}>
              <Text fontSize={"md"} color={"gray.600"}>
                *Todos los datos son confidenciales y para uso exclusivo del
                sistema de acceso a la privada, no serán compartidos a ninguna
                persona o institución.
              </Text>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
};

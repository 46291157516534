import {
  Card,
  CardBody,
  Flex,
  Heading,
  IconButton,
  List,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { FaEllipsisVertical, FaTrash, FaUserXmark } from "react-icons/fa6";
import { RegisterResidentForm } from "../forms/RegisterResidentForm";
import { MessageBox } from "../MessageBox";
import { useStore } from "../../hooks/useStore";
import { useAuth } from "../../hooks/useAuth";

export const RegisterResidentModule = ({ residents, direccion }) => {
  const { usuario } = useAuth();
  const { handleError, putResidentVisit } = useStore();
  const { nombre } = usuario;

  const handleRemove = async (id) => {
    try {
      await putResidentVisit(2, id, nombre, direccion);
    } catch (error) {
      handleError(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await putResidentVisit(5, id, nombre, direccion);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Flex
      alignItems="center"
      bg="#edf2f7"
      borderRadius="lg"
      borderWidth={1}
      boxShadow="md"
      direction="column"
      mt={4}
      padding={4}
    >
      <Flex w="100%" justifyContent="space-between" alignItems="center">
        <Heading as="h2" size="lg">
          Residentes
        </Heading>

        <RegisterResidentForm usuario={nombre} direccion={direccion} />
      </Flex>

      {residents?.length > 0 ? (
        <List spacing={2} w={"full"} mt={4}>
          {residents.map((resident, index) => (
            <Card key={index} w={"full"} boxShadow={"md"}>
              <CardBody
                alignItems={"center"}
                borderColor={"green.400"}
                borderLeftWidth={8}
                borderRadius={"lg"}
                display={"flex"}
                justifyContent={"space-between"}
              >
                <Text fontSize={"lg"}>{resident.nombre}</Text>
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    icon={<FaEllipsisVertical />}
                    variant="filled"
                  />
                  <MenuList>
                    <MenuItem
                      icon={<FaUserXmark />}
                      onClick={() => handleRemove(resident.idVehiculo)}
                    >
                      Quitar
                    </MenuItem>
                    <MenuItem
                      icon={<FaTrash />}
                      onClick={() => handleDelete(resident.idVehiculo)}
                    >
                      Eliminar
                    </MenuItem>
                  </MenuList>
                </Menu>
              </CardBody>
            </Card>
          ))}
        </List>
      ) : (
        <Flex width="100%" h="100%" justify="center" align="center">
          <MessageBox description="Sin registros asociado" status="info" />
        </Flex>
      )}
    </Flex>
  );
};

import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import {
  FaAngleLeft,
  FaAngleRight,
  FaCircleCheck,
  FaCircleExclamation,
  FaUserCheck,
  FaUserLock,
} from "react-icons/fa6";
import { GuardEditForm } from "../forms/GuardEditForm";
import { RenderLoading } from "../RenderAlert";
import { useStore } from "../../hooks/useStore";
import { useAuth } from "../../hooks/useAuth";

export const TablaGuards = ({ guards }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { putGuardInfo } = useStore();
  const { usuario } = useAuth();
  const [process, setProcess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * 5;
  const indexOfFirstItem = indexOfLastItem - 5;
  const currentItems = guards?.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(guards?.length / 5);

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const renderPagination = () => {
    const adjacentPages = 2;
    const totalAdjacentPages = adjacentPages * 2 + 1;

    let startPage = Math.max(1, currentPage - adjacentPages);
    let endPage = Math.min(totalPages, startPage + totalAdjacentPages - 1);

    if (endPage - startPage < totalAdjacentPages - 1) {
      startPage = Math.max(1, endPage - totalAdjacentPages + 1);
    }

    return (
      <>
        <IconButton
          aria-label="pagina anterior"
          bg="white"
          borderRadius="full"
          icon={<Icon as={FaAngleLeft} />}
          onClick={() =>
            setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
          }
          variant="outline"
          isDisabled={currentPage === 1}
        />

        {pageNumbers.slice(startPage - 1, endPage).map((pageNumber) => (
          <Button
            key={pageNumber}
            colorScheme="twitter"
            borderRadius="full"
            mx={1}
            onClick={() => goToPage(pageNumber)}
            variant={pageNumber === currentPage ? "solid" : "outline"}
            width="30px"
            style={{
              fontWeight: pageNumber === currentPage ? "bold" : "normal",
            }}
          >
            {pageNumber}
          </Button>
        ))}

        <IconButton
          aria-label="pagina siguiente"
          bg="white"
          borderRadius="full"
          icon={<Icon as={FaAngleRight} />}
          variant="outline"
          isDisabled={currentPage === totalPages}
          onClick={() =>
            setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))
          }
        />
      </>
    );
  };

  // Cambiar a una página específica
  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleOpenModal = (process) => {
    setProcess(process);
    onOpen();
  };

  const handleConfirm = async () => {
    onClose();
    setIsLoading(true);
    await putGuardInfo(process, usuario.nombre);
    setIsLoading(false);
  };
  return (
    <>
      {isLoading && <RenderLoading state={isLoading} />}
      {currentItems?.length > 0 && (
        <TableContainer borderRadius={10} boxShadow="md" bg="white">
          <Table variant="simple">
            <TableCaption>
              <Flex justify="space-between" align="center">
                <Box>{renderPagination()}</Box>
              </Flex>
            </TableCaption>
            <Thead bg="cyan.400">
              <Tr>
                <Th>nombre</Th>
                <Th>telefono</Th>
                <Th>correo</Th>
                <Th>estado</Th>
                <Th>acciones</Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentItems.map((value, index) => (
                <Tr key={index}>
                  <Td>{value?.nombre ? value.nombre : "NA"}</Td>
                  <Td>{value?.telefono ? value.telefono : "NA"}</Td>
                  <Td>{value?.correo ? value.correo : "NA"}</Td>
                  <Td>
                    {value.idEstado === 1 ? (
                      <Icon as={FaCircleCheck} color="green.300" />
                    ) : (
                      <Icon as={FaCircleExclamation} color="yellow.300" />
                    )}
                  </Td>
                  <Td>
                    <GuardEditForm guard={value} />

                    {value.idEstado === 1 ? (
                      <Tooltip hasArrow label="Suspender">
                        <IconButton
                          aria-label="suspender guardia"
                          icon={<FaUserLock />}
                          onClick={() => handleOpenModal(2)}
                          size="lg"
                          variant="filled"
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip hasArrow label="Reactivar">
                        <IconButton
                          aria-label="reactivar guardia"
                          icon={<FaUserCheck />}
                          onClick={() => handleOpenModal(3)}
                          size="lg"
                          variant="filled"
                        />
                      </Tooltip>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody p={6}>
            <Text>¿Estás seguro de realizar esta acción?</Text>
            <Flex justify="flex-end" mt={4}>
              <Button colorScheme="twitter" mr={2} onClick={handleConfirm}>
                Confirmar
              </Button>
              <Button variant="outline" onClick={onClose}>
                Cancelar
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Flex, Heading } from "@chakra-ui/react";
import { useStore } from "../../hooks/useStore";
import { TablaGuards } from "../tables/TablaGuards";
import { RenderLoading } from "../RenderAlert";
import { MessageBox } from "../MessageBox";

export const GuardModule = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { guards, getsGuards } = useStore();

  useEffect(() => {
    const fetchGuards = async () => {
      setIsLoading(true);
      await getsGuards();
      setIsLoading(false);
    };

    fetchGuards();
  }, []);

  return (
    <>
      {isLoading && <RenderLoading state={isLoading} />}

      <Flex
        bg="white"
        borderRadius="lg"
        borderWidth={1}
        boxShadow="md"
        direction="column"
        justifyContent="space-between"
        mt={4}
        padding={4}
        w="100%"
      >
        <Flex w={"100%"} justifyContent={"space-between"} alignItems={"center"}>
          <Heading as="h2" size="lg" mb={4}>
            Guardias
          </Heading>
        </Flex>

        {guards?.length > 0 ? (
          <TablaGuards guards={guards} />
        ) : (
          <Flex justify="center">
            <MessageBox message="No hay registros asociados." status="info" />
          </Flex>
        )}
      </Flex>
    </>
  );
};

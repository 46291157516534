import {
  Card,
  CardBody,
  Flex,
  Heading,
  IconButton,
  List,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { FaEllipsisVertical, FaUserCheck } from "react-icons/fa6";
import { useStore } from "../../hooks/useStore";
import { useAuth } from "../../hooks/useAuth";

const Services = [
  {
    id: 1,
    name: "Transportes",
    idTipo: "Servicio Transporte",
  },
  {
    id: 2,
    name: "Alimentos",
    idTipo: "Servicio Alimentos",
  },
  {
    id: 3,
    name: "Paquetería",
    idTipo: "Servicio Paqueteria",
  },
  {
    id: 4,
    name: "Jardinería",
    idTipo: "Servicio Jardineria",
  },
  {
    id: 5,
    name: "Otros servicios",
    idTipo: "Servicios Varios",
  },
];

export const ServiceVisitModule = () => {
  const { usuario } = useAuth();
  const { handleError, registerServiceVisit } = useStore();
  const { dir: direccion, nombre } = usuario;

  const handleAllow = async (tipo) => {
    try {
      await registerServiceVisit(nombre, { direccion, nombre, idTipo: tipo });
    } catch (error) {
      handleError(error);
    }
  };
  return (
    <Flex
      alignItems="center"
      bg="#edf2f7"
      borderRadius="lg"
      borderWidth={1}
      boxShadow="md"
      direction="column"
      justifyContent="space-between"
      mt={4}
      padding={4}
    >
      <Flex w={"100%"} justifyContent={"space-between"} alignItems={"center"}>
        <Heading as="h2" size="lg">
          Servicios
        </Heading>
      </Flex>

      <List spacing={2} w={"full"} mt={4}>
        {Services.map((service, index) => (
          <Card key={index} w={"full"} boxShadow={"md"}>
            <CardBody
              alignItems={"center"}
              borderColor={"blue.400"}
              borderLeftWidth={8}
              borderRadius={"lg"}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Text fontSize={"lg"}>{service.name}</Text>
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<FaEllipsisVertical />}
                  variant="filled"
                />
                <MenuList>
                  <MenuItem
                    icon={<FaUserCheck />}
                    onClick={() => handleAllow(service.idTipo)}
                  >
                    Permitir Acceso
                  </MenuItem>
                </MenuList>
              </Menu>
            </CardBody>
          </Card>
        ))}
      </List>
    </Flex>
  );
};

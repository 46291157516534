/* 
  Router principal, se maneja un router privado y publico dependiendo si el usuario esta autenticado o no.
*/

import React from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import AuthRouter from "./AuthRouter";
import HomeRouter from "./HomeRouter";
import PublicRouter from "./PublicRouter";
import PrivateRouter from "./PrivateRouter";

import { StoreState } from "../context/store/StoreState";

export const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/*"
          element={
            <PrivateRouter>
              <StoreState>
                <HomeRouter />
              </StoreState>
            </PrivateRouter>
          }
        />

        <Route
          path="/auth/*"
          element={
            <PublicRouter>
              <AuthRouter />
            </PublicRouter>
          }
        />

        <Route path="*" element={<Navigate to="/auth/login" replace />} />
      </Routes>
    </Router>
  );
};

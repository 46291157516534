/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useStore } from "../../hooks/useStore";
import { RenderLoading } from "../RenderAlert";
import {
  Container,
  Flex,
  Icon,
  IconButton,
  Input,
  ScaleFade,
} from "@chakra-ui/react";
import { FaMagnifyingGlass, FaXmark } from "react-icons/fa6";
import { TablaAddress } from "../tables/TablaAddress";
import { MessageBox } from "../MessageBox";

export const ContributionModule = ({ startDate, endDate }) => {
  const { address, getsAddressList } = useStore();

  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchEmpty, setIsSearchEmpty] = useState(true);

  const filteredAddress = () => {
    const inputValue = search.trim().toLocaleLowerCase();
    const inputLength = inputValue.length;
    if (inputLength === 0) return address;

    const filtrados = address.filter((value) => {
      return value.trim().toLowerCase().includes(inputValue);
    });
    return filtrados;
  };

  const onSearchChange = ({ target }) => {
    setSearch(target.value);
    setIsSearchEmpty(target.value.length === 0);
  };

  const clearSearch = () => {
    setSearch("");
    setIsSearchEmpty(true);
    // Puedes realizar otras acciones al limpiar la búsqueda, si es necesario.
  };

  useEffect(() => {
    const fetchAddress = async () => {
      try {
        setIsLoading(true);
        await getsAddressList();
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    fetchAddress();
  }, []);

  return (
    <>
      {isLoading && <RenderLoading state={isLoading} />}

      <ScaleFade initialScale={0.9} in={true}>
        <Container maxW={"full"} mb={6}>
          <Flex justifyContent="space-evenly" marginBottom={5}>
            <Flex width="100%">
              <Input
                borderLeftRadius={10}
                background="white"
                borderRadius="none"
                boxShadow="md"
                htmlSize={40}
                onChange={onSearchChange}
                placeholder="Buscar por dirección"
                size="lg"
                value={search}
              />

              {isSearchEmpty ? (
                <IconButton
                  aria-label="Buscar"
                  bg="white"
                  borderRadius="none"
                  borderRightRadius={10}
                  boxShadow="md"
                  icon={<Icon as={FaMagnifyingGlass} />}
                  size="lg"
                  variant="solid"
                />
              ) : (
                <IconButton
                  aria-label="Limpiar buscador"
                  bg="white"
                  borderRadius="none"
                  borderRightRadius={10}
                  boxShadow="md"
                  icon={<Icon as={FaXmark} />}
                  onClick={clearSearch}
                  size="lg"
                  variant="solid"
                />
              )}
            </Flex>
          </Flex>

          {address?.length > 0 ? (
            <TablaAddress
              address={filteredAddress()}
              startDate={startDate}
              endDate={endDate}
            />
          ) : (
            <Flex
              bg="white"
              borderRadius="lg"
              borderWidth={1}
              boxShadow="md"
              justify="center"
              mt={4}
              py={6}
              width="full"
            >
              <MessageBox message="Sin registro asociado" status="info" />
            </Flex>
          )}
        </Container>
      </ScaleFade>
    </>
  );
};

import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Select,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { ContributionForm } from "../forms/ContributionForm";

export const TablaAddress = ({ address, startDate, endDate }) => {
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = address?.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(address?.length / itemsPerPage);

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const renderPagination = () => {
    const adjacentPages = 2;
    const totalAdjacentPages = adjacentPages * 2 + 1;

    let startPage = Math.max(1, currentPage - adjacentPages);
    let endPage = Math.min(totalPages, startPage + totalAdjacentPages - 1);

    if (endPage - startPage < totalAdjacentPages - 1) {
      startPage = Math.max(1, endPage - totalAdjacentPages + 1);
    }

    return (
      <>
        <IconButton
          aria-label="pagina anterior"
          bg="white"
          borderRadius="full"
          icon={<Icon as={FaAngleLeft} />}
          onClick={() =>
            setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
          }
          variant="outline"
          isDisabled={currentPage === 1}
        />

        {pageNumbers.slice(startPage - 1, endPage).map((pageNumber) => (
          <Button
            key={pageNumber}
            bg={pageNumber === currentPage ? "blue.400" : "white"}
            borderRadius="full"
            color={pageNumber === currentPage ? "white" : "black"}
            mx={1}
            onClick={() => goToPage(pageNumber)}
            variant={pageNumber === currentPage ? "solid" : "outline"}
            width="30px"
            style={{
              fontWeight: pageNumber === currentPage ? "bold" : "normal",
            }}
          >
            {pageNumber}
          </Button>
        ))}

        <IconButton
          aria-label="pagina siguiente"
          bg="white"
          borderRadius="full"
          icon={<Icon as={FaAngleRight} />}
          variant="outline"
          isDisabled={currentPage === totalPages}
          onClick={() =>
            setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))
          }
        />
      </>
    );
  };

  // Cambiar a una página específica
  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (e) => {
    setCurrentPage(1);
    setItemsPerPage(parseInt(e.target.value, 10));
  };
  return (
    <>
      <Flex align="center" mb={4}>
        <Select
          onChange={handleItemsPerPageChange}
          value={itemsPerPage}
          bg="white"
          boxShadow={"md"}
          width="80px"
          mr={2}
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
        </Select>
        <Text>Elementos por página</Text>
      </Flex>

      {currentItems?.length > 0 && (
        <TableContainer borderRadius={10} boxShadow="md" bg="white">
          <Table variant="simple">
            <TableCaption>
              <Flex justify="space-between" align="center">
                <Box>{renderPagination()}</Box>
              </Flex>
            </TableCaption>
            <Thead bg="cyan.400">
              <Tr>
                <Th>Dirección</Th>
                <Th>Acciones</Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentItems.map((value, index) => (
                <Tr key={index}>
                  <Td>{value}</Td>
                  <Td>
                    <ContributionForm
                      startDate={startDate}
                      endDate={endDate}
                      selectedAddress={selectedAddress}
                      setSelectedAddress={setSelectedAddress}
                      value={value}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

import React, { useState } from "react";
import { Form, Formik } from "formik";
import {
  Button,
  Center,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa6";
import { InputNormal } from "./Inputs";
import { useStore } from "../../hooks/useStore";

const initialValues = {
  nombre: "",
  comentarios: "",
};

export const RegisterResidentForm = ({ usuario, direccion }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const { createResident, handleError } = useStore();

  const handleRegister = async (values) => {
    try {
      setIsLoading(true);
      await createResident(usuario, { ...values, direccion });
      setIsLoading(false);
      onClose();
    } catch (error) {
      handleError(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Tooltip hasArrow label="Registrar residente">
        <IconButton
          aria-label="registrar residente"
          bg="white"
          boxShadow="md"
          icon={<FaPlus />}
          onClick={onOpen}
          size="lg"
          variant="filled"
        />
      </Tooltip>

      <Modal
        isCentered
        isOpen={isOpen}
        motionPreset="slideInBottom"
        onClose={onClose}
        scrollBehavior="inside"
        size="lg"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Registrar nuevo residente</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RegisterForm
              initialValues={initialValues}
              isLoading={isLoading}
              onSubmit={handleRegister}
              onClose={onClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const RegisterForm = ({ initialValues, isLoading, onSubmit, onClose }) => {
  const formatToCapitalize = (text) => {
    return text
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({
        errors,
        values,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        resetForm,
      }) => (
        <Form>
          <InputNormal
            label="Nombre"
            name="nombre"
            type="text"
            handleBlur={handleBlur}
            handleChange={(e) => {
              const formattedValue = formatToCapitalize(e.target.value);
              handleChange({
                target: { name: "nombre", value: formattedValue },
              });
            }}
            value={values.nombre}
          />

          <Text mb="8px" mt={2} fontWeight={"500"}>
            Comentarios
          </Text>
          <Textarea
            label="Comentarios"
            name="comentarios"
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Ninguno"
            value={values.comentarios}
          />

          <Center my={5}>
            <Button
              isLoading={isLoading}
              colorScheme="blue"
              onClick={handleSubmit}
              ml={4}
            >
              Finalizar
            </Button>

            <Button
              isDisabled={isLoading}
              colorScheme="gray"
              variant="outline"
              onClick={() => {
                resetForm();
                onClose();
              }}
              ml={4}
            >
              Cancelar
            </Button>
          </Center>
        </Form>
      )}
    </Formik>
  );
};

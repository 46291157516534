import React from "react";
import {
  Box,
  //Button,
  CloseButton,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import {
  //FaArrowRightFromBracket,
  FaArrowTrendDown,
  FaFileInvoiceDollar,
  FaFileLines,
  FaHouse,
  FaHouseLock,
  FaListCheck,
  FaShield,
  FaUsers,
} from "react-icons/fa6";
import { NavItem } from "./NavItem";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

const LinkItems = [
  { name: "Inicio", icon: FaHouse, path: "/residente/resumen", allowedRol: 7 },
  { name: "Mis Visitas", icon: FaUsers, path: "/mis-visitas", allowedRol: 7 },
  { name: "Mi Historial", icon: FaListCheck, path: "/historial", allowedRol: 7 },
  { name: "Administración", icon: FaHouse, path: "/administracion/dashboard", allowedRol: 2 },
  { name: "Caseta", icon: FaHouseLock, path: "/caseta", allowedRol: 4 },
  { name: "Aportaciones", icon: FaFileInvoiceDollar, path: "/administracion/aportaciones", allowedRol: [2, 3] },
  { name: "Aportaciones", icon: FaFileInvoiceDollar, path: "/aportaciones", allowedRol: 4 },
  { name: "Gastos", icon: FaArrowTrendDown, path: "/administracion/gastos", allowedRol: [2,3] },
  { name: "Reportes", icon: FaFileLines, path: "/administracion/reportes", allowedRol: 2 },
  { name: "Seguridad", icon: FaShield, path: "/administracion/seguridad", allowedRol: [2, 4] },
  // { name: "Root", icon: FaClipboardUser, path: " ", allowedRol: 7 },
];

export const SidebarContent = ({ onClose, ...rest }) => {
  const navigate = useNavigate();
  const { rol } = useAuth();

  const handleGoHome = () => {
    navigate("/");
  };

  const filteredLinkItems = LinkItems.filter((link) => {
    if (Array.isArray(link.allowedRol)) {
      return link.allowedRol.includes(rol);
    } else {
      return link.allowedRol === rol;
    }
  });

  return (
    <Box
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      display="flex"
      flexDirection="column"
      h="full"
      pos="fixed"
      transition="3s ease"
      w={{ base: "full", md: 60 }}
      {...rest}
    >
      <Flex h="20" align="center" mx="8" justify="space-between">
        <Text
          fontSize="20px"
          fontFamily="Gugi"
          fontWeight="bold"
          textColor={"blue.600"}
          onClick={handleGoHome}
          _hover={{ cursor: "pointer" }}
        >
          Villalba Residencial
        </Text>
        <CloseButton display={{ base: "flex", lg: "none" }} onClick={onClose} />
      </Flex>
      {filteredLinkItems.map((link) => (
        <NavItem
          key={link.name}
          icon={link.icon}
          navigateTo={link.path}
          onClose={onClose}
        >
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

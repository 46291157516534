import React from "react";
import { Box, Container, Heading, ScaleFade } from "@chakra-ui/react";
import { HistoryReportsModule } from "../../components/modules/HistoryReportsModule";
import { GuardModule } from "../../components/modules/GuardModule";
import { DefaultersModule } from "../../components/modules/DefaultersModule";

export const ReportsScreen = () => {
  return (
    <>
      <ScaleFade initialScale={0.9} in={true}>
        <Box>
          <Heading
            fontSize={{ base: "2xl", sm: "4xl" }}
            fontWeight="bold"
            mb={4}
          >
            Reportes
          </Heading>

          <Container maxW={"full"}>
            <HistoryReportsModule />
            <DefaultersModule />
            <GuardModule />
          </Container>
        </Box>
      </ScaleFade>
    </>
  );
};

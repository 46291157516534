/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, Flex, Heading, Icon, IconButton, Input } from "@chakra-ui/react";
import { FaMagnifyingGlass, FaXmark } from "react-icons/fa6";
import { useStore } from "../../hooks/useStore";
import { TablaResidentExpiration } from "../tables/TablaResidentExpiration";
import { RenderLoading } from "../RenderAlert";

export const ResidentExpirationModule = () => {
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchEmpty, setIsSearchEmpty] = useState(true);

  const { expirationList, getResidentExpirationList } = useStore();
  const filteredAddress = () => {
    const inputValue = search.trim().toLocaleLowerCase();
    const inputLength = inputValue.length;
    if (inputLength === 0) return expirationList;

    const filtrados = expirationList.filter((value) => {
      return value?.idResidencia.trim().toLowerCase().includes(inputValue);
    });
    return filtrados;
  };

  const onSearchChange = ({ target }) => {
    setSearch(target.value);
    setIsSearchEmpty(target.value.length === 0);
  };

  const clearSearch = () => {
    setSearch("");
    setIsSearchEmpty(true);
    // Puedes realizar otras acciones al limpiar la búsqueda, si es necesario.
  };

  useEffect(() => {
    const fetchInfo = async () => {
      setIsLoading(true);
      await getResidentExpirationList();
      setIsLoading(false);
    };

    fetchInfo();
  }, []);
  return (
    <>
      {isLoading ? (
        <RenderLoading state={isLoading} />
      ) : (
        <Box mt={10}>
          <Heading mb={4}>Vencimientos</Heading>

          <Flex
            direction={{ base: "column", md: "row" }}
            justifyContent="space-evenly"
            marginBottom={5}
          >
            <Flex width={{ base: "100%", md: "100%" }}>
              <Input
                background="white"
                borderRadius="none"
                borderLeftRadius={10}
                boxShadow="md"
                htmlSize={40}
                onChange={onSearchChange}
                placeholder="Buscar por dirección"
                size="lg"
                value={search}
              />

              {isSearchEmpty ? (
                <IconButton
                  aria-label="Buscar"
                  bg="white"
                  borderRadius="none"
                  borderRightRadius={10}
                  boxShadow="md"
                  icon={<Icon as={FaMagnifyingGlass} />}
                  size="lg"
                  variant="solid"
                />
              ) : (
                <IconButton
                  aria-label="Limpiar buscador"
                  bg="white"
                  borderRadius="none"
                  borderRightRadius={10}
                  boxShadow="md"
                  icon={<Icon as={FaXmark} />}
                  onClick={clearSearch}
                  size="lg"
                  variant="solid"
                />
              )}
            </Flex>
          </Flex>
          <TablaResidentExpiration address={filteredAddress()} />
        </Box>
      )}
    </>
  );
};

/* eslint-disable import/no-anonymous-default-export */
import {
  LOGIN_LOADING,
  LOGIN,
  LOGOUT,
  LOAD_USER_DATA,
  VALIDATE_USER_DATA,
} from "./actions";

export default (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        isLoginLoading: false,
        usuario: payload,
        rol: payload.idRol,
        token: payload.token,
      };

    case LOGOUT:
      return {
        usuario: payload,
        isAuthenticated: false,
      };

    case LOGIN_LOADING:
      return {
        isLoginLoading: payload,
      };

    case LOAD_USER_DATA:
      return {
        ...state,
        usuario: payload.usuario,
        rol: payload.rol,
      };

    case VALIDATE_USER_DATA:
      return {
        ...state,
        userDataRecovery: payload,
      };

    default:
      return state;
  }
};

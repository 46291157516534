import React from "react";
import {
  Flex,
  Heading,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { MessageBox } from "../MessageBox";

export const ResidentContributionsModule = ({ contributions }) => {
  const formatDate = (isoDateString) => {
    const dateObject = new Date(isoDateString);
    const day = dateObject.getDate().toString().padStart(2, "0");
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const year = dateObject.getFullYear();

    return `${day}/${month}/${year}`;
  };

  return (
    <Flex
      alignItems="center"
      bg="#edf2f7"
      borderRadius="lg"
      borderWidth={1}
      boxShadow="md"
      direction="column"
      justifyContent="space-between"
      mt={4}
      padding={4}
      w="100%"
    >
      <Flex w={"100%"} justifyContent={"space-between"} alignItems={"center"}>
        <Heading as="h2" size="lg" mb={4}>
          Mis Aportaciones
        </Heading>
      </Flex>

      {contributions?.length > 0 ? (
        <TableContainer
          bg="white"
          borderRadius="lg"
          boxShadow="md"
          mt={4}
          w="full"
        >
          <Table variant="simple">
            <TableCaption>Paginacion en construccion</TableCaption>
            <Thead>
              <Tr>
                <Th w={"90%"}>Recibido por</Th>
                <Th textAlign={"center"}>Fecha</Th>
                <Th textAlign={"center"}>Cantidad</Th>
              </Tr>
            </Thead>
            <Tbody>
              {contributions.map((payment, index) => (
                <Tr key={index}>
                  <Td>{payment.idResidente}</Td>
                  <Td>{formatDate(payment.fecha)}</Td>
                  <Td textAlign={"end"}>{payment.ocupantes} $</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Flex width="100%" h="100%" justify="center" align="center">
          <MessageBox description="Sin registros asociado" status="info" />
        </Flex>
      )}
    </Flex>
  );
};

import React from "react";
import { Card, CardBody, Heading, Icon, Stack, Text } from "@chakra-ui/react";

const BaseCard = ({ children, bg="initial" }) => {
  return (
    <Card
    bg={bg}
      alignItems="center"
      borderRadius="lg"
      boxShadow="md"
      direction="row"
      display="flex"
      overflow="hidden"
      px={4}
      py={2}
      variant="filled"
      w={{ base: "200px", md: "280px" }}
    >
      {children}
    </Card>
  );
};

export const CardWithIcon = ({
  bg,
  description,
  icon,
  iconColor,
  iconBg,
  title,
}) => {
  return (
    <BaseCard bg={bg}>
      <Icon
        as={icon}
        bg={iconBg}
        color={iconColor}
        flex="30%"
        h={{ base: "90%", lg: "70%" }}
        p={5}
        rounded="lg"
      />

      <Stack w="70%">
        <CardBody>
          <Heading size="lg">{title}</Heading>

          <Text fontSize={"lg"}>{description}</Text>
        </CardBody>
      </Stack>
    </BaseCard>
  );
};

export const CardSimple = ({ bg, description }) => {
  <BaseCard>
    <Stack w="70%">
      <CardBody width={"100%"} bg={"yellow"}>
        <Text fontSize={"lg"}>{description}</Text>
      </CardBody>
    </Stack>
  </BaseCard>;
};

/*
    maneja todas las funciones del store context que engloba todas las acciones de visitas por ahora solamente
*/

import React, { useReducer } from "react";
import { useToast } from "@chakra-ui/react";
import { format } from "date-fns";
import { StoreContext } from "./StoreContext";
import StoreReducer from "./StoreReducer";
import { API } from "../../api/config";
import { setAuthToken } from "../../service/AuthService";

export const StoreState = ({ children }) => {
  const toast = useToast();
  const initialState = {
    address: [],
    contributionsInfo: {},
    dashboardInfo: {},
    expirationList: [],
    residentHistory: [],
    statusResident: false,
    vehicles: [],
    visit: {},
    expenses: [],
    securityHistory: [],
    reportsHistory: [],
    guards: [],
    defaulters: []
  };

  const [state, dispatch] = useReducer(StoreReducer, initialState);

  /* 
  ################ Vehicle methods ###############
  */

  const getsVisits = async () => {
    try {
      const urlVehicles = "/caseta/vehiculosauth";
      const urlPedestrian = "/caseta/peatonesauth";
      const { data: dataVehicles } = await API.get(urlVehicles);
      const { data: dataPedestrian } = await API.get(urlPedestrian);

      dispatch({
        type: "GETS_VEHICLES",
        payload: dataVehicles.response,
      });

      dispatch({
        type: "GETS_PEDESTRIAN",
        payload: dataPedestrian.response,
      });
    } catch (error) {
      handleError(error);
      handleUnauthorizedError(error);
    }
  };

  const getsVehiclesAuthorized = async () => {
    try {
      const url = "/caseta/in";
      const { data } = await API.get(url);

      dispatch({
        type: "GETS_VEHICLES_AUTHORIZED",
        payload: data.resultado,
      });
    } catch (error) {
      handleError(error);
      handleUnauthorizedError(error);
    }
  };

  /* 
  ################ Residential methods ###############
  */

  const registerNewVisit = async (process, usuario, dataForm) => {
    try {
      const url = `/residente/registros?process=${process}&usuario=${usuario}`;
      await API.post(url, dataForm);
      await getInfoDashboard(dataForm.direccion);

      toast({
        description: "Se ha registrado su visita.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      handleError(error);
      handleUnauthorizedError(error);
    }
  };

  const putResidentVisit = async (process, idVehiculo, usuario, direccion) => {
    const url = `/residente/activity?process=${process}&idvehiculo=${idVehiculo}&usuario=${usuario}&direccion=${direccion}`;

    toast({
      description: "Se han actualizado sus visitas.",
      status: "success",
      position: "bottom-left",
      variant: "left-accent",
      duration: 4000,
      isClosable: true,
    });
    try {
      await API.put(url);
      await getInfoDashboard(direccion);
    } catch (error) {
      handleError(error);
      handleUnauthorizedError(error);
    }
  };

  const registerServiceVisit = async (usuario, dataForm) => {
    try {
      const url = `residente/registros?process=5&usuario=${usuario}`;
      await API.post(url, dataForm);
      await getInfoDashboard(dataForm.direccion);

      toast({
        description: `Se ha registrado una autorización para ${dataForm.idTipo}.`,
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      handleError(error);
      handleUnauthorizedError(error);
    }
  };

  const createResident = async (usuario, dataForm) => {
    try {
      const url = `residente/registros?process=7&usuario=${usuario}`;
      await API.post(url, dataForm);
      await getInfoDashboard(dataForm.direccion);

      toast({
        description: "Se ha registrado con éxito.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      handleError(error);
      handleUnauthorizedError(error);
    }
  };

  const registerResidentVehicle = async (usuario, dataForm) => {
    try {
      const url = `residente/registros?process=6&usuario=${usuario}`;
      await API.post(url, dataForm);
      await getInfoDashboard(dataForm.direccion);

      toast({
        description: "Se ha registrado con éxito.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      handleError(error);
      handleUnauthorizedError(error);
    }
  };

  const getInfoDashboard = async (direccion) => {
    try {
      const url = `residente/dashboard?direccion=${direccion}`;
      const { data } = await API.get(url);
      console.log(data);

      dispatch({
        type: "GET_DASHBOARD_INFO",
        payload: data,
      });
    } catch (error) {
      handleError(error);
      handleUnauthorizedError(error);
    }
  };

  const getInfoContributions = async () => {
    try {
      const fechaActual = new Date();
      const año = fechaActual.getFullYear();
      const mes = ("0" + (fechaActual.getMonth() + 1)).slice(-2);
      const dia = ("0" + fechaActual.getDate()).slice(-2);
      const fechaFormateada = año + "/" + mes + "/" + dia;

      const urlAccounts = `/administracion/ctapriv?fecha=${fechaFormateada}&fecha1=${fechaFormateada}`;
      const { data } = await API.get(urlAccounts);
      const urlUserCounts = "/administracion/dashboard";
      const { data: data2 } = await API.get(urlUserCounts);

      dispatch({
        type: "GET_CONTRIBUTIONS_INFO",
        payload: { ...data, ...data2 },
      });
    } catch (error) {
      handleError(error);
      handleUnauthorizedError(error);
    }
  };

  const getStatusResident = async (direccion) => {
    try {
      const fechaActual = new Date();
      const año = fechaActual.getFullYear();
      const mes = ("0" + (fechaActual.getMonth() + 1)).slice(-2);
      const dia = ("0" + fechaActual.getDate()).slice(-2);
      const fechaFormateada = año + "/" + mes + "/" + dia;

      const url = `/administracion/validity?direccion=${direccion}&fecha=${fechaFormateada}`;
      const { data } = await API.get(url);

      dispatch({
        type: "GET_STATUS_RESIDENT",
        payload: data.response,
      });
    } catch (error) {
      handleError(error);
      handleUnauthorizedError(error);
    }
  };

  const getResidentHistory = async (fecha, direccion) => {
    try {
      const url = `/residente/historial?direccion=${direccion}&fecha=${fecha}`;
      const { data } = await API.get(url);

      dispatch({
        type: "GET_RESIDENT_HISTORY",
        payload: data.historial,
      });
    } catch (error) {
      handleError(error);
      handleUnauthorizedError(error);
    }
  };

  /*
################ Caseta methods ###############
*/

  const getsAddressList = async () => {
    try {
      const url = "/caseta/listdirections";
      const { data } = await API.get(url);

      dispatch({
        type: "GETS_ADDRESS_LIST",
        payload: data.dir,
      });
    } catch (error) {
      handleError(error);
      handleUnauthorizedError(error);
    }
  };

  //* construir el dispatch y state correspondiente
  //! no esta en uso actualmente
  const getsVehiclesSearch = async (term) => {
    try {
      const url = `/caseta/search?search=${term}`;
      const { data } = await API.get(url);
      return data;
    } catch (error) {
      handleError(error);
      handleUnauthorizedError(error);
    }
  };

  /*
################ Administracion methods ###############
*/

  const getResidentExpirationList = async () => {
    try {
      const url = "/administracion/vencimientos";
      const { data } = await API.get(url);

      dispatch({
        type: "GETS_EXPIRATION_LIST",
        payload: data.res,
      });
    } catch (error) {
      handleError(error);
      handleUnauthorizedError(error);
    }
  };

  const putResidentExpiration = async (usuario, dataUser) => {
    try {
      const url = `/administracion/updexp?usuario=${usuario}`;
      await API.put(url, dataUser);

      toast({
        description: "Se ha modificado la fecha de vencimiento con éxito.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      handleError(error);
      handleUnauthorizedError(error);
    }
  };

  const getExpensesByDateRange = async (fechaini, fecha) => {
    try {
      const url = `/reportes/gastos?fechaini=${fechaini}&fecha=${fecha}`;
      const { data } = await API.get(url);

      dispatch({
        type: "GETS_EXPENSES_LIST",
        payload: data.list,
      });
    } catch (error) {
      handleError(error);
      handleUnauthorizedError(error);
    }
  };

  const postRegisterExpense = async (usuario, dataForm, startDate, endDate) => {
    try {
      const url = `/administracion/gasto?usuario=${usuario}`;
      await API.post(url, dataForm);
      const fecha1 = format(startDate, "yyyy-MM-dd");
      const fecha2 = format(endDate, "yyyy-MM-dd");
      await getExpensesByDateRange(fecha1, fecha2);

      toast({
        description: "Se ha registrado un nuevo documento.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      handleError(error);
      handleUnauthorizedError(error);
    }
  };

  const putExpenseAdmin = async (process, dataForm, startDate, endDate) => {
    try {
      const url = `/reportes/delgasto?process=${process}`;
      await API.put(url, dataForm);
      const fecha1 = format(startDate, "yyyy-MM-dd");
      const fecha2 = format(endDate, "yyyy-MM-dd");
      await getExpensesByDateRange(fecha1, fecha2);
      toast({
        description: "Se ha completado la acción con éxito.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      handleError(error);
      handleUnauthorizedError(error);
    }
  };

  const getContributionsByRange = async (fecha1, fecha2) => {
    try {
      const url = `/reportes/aportaciones?fechaini=${fecha1}&fecha=${fecha2}`;
      const { data } = await API.get(url);

      dispatch({
        type: "GETS_CONTRIBUTIONS_LIST",
        payload: data.list,
      });
    } catch (error) {
      handleError(error);
      handleUnauthorizedError(error);
    }
  };

  const postContributionAdmin = async (
    usuario,
    dataForm,
    startDate,
    endDate
  ) => {
    try {
      const url = `/administracion/addaportacion?usuario=${usuario}`;
      await API.post(url, dataForm);
      const fecha1 = format(startDate, "yyyy-MM-dd");
      const fecha2 = format(endDate, "yyyy-MM-dd");
      await getContributionsByRange(fecha1, fecha2);

      toast({
        description: "Se ha registrado un nuevo aporte.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      handleError(error);
      handleUnauthorizedError(error);
    }
  };

  const putContributionAdmin = async (
    process,
    dataForm,
    startDate,
    endDate
  ) => {
    try {
      const url = `/reportes/delaport?process=${process}`;
      await API.put(url, dataForm);
      const fecha1 = format(startDate, "yyyy-MM-dd");
      const fecha2 = format(endDate, "yyyy-MM-dd");
      await getContributionsByRange(fecha1, fecha2);
      toast({
        description: "Se ha completado la acción con éxito.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      handleError(error);
      handleUnauthorizedError(error);
    }
  };

  const getsSecurityHistory = async (fecha) => {
    try {
      const url = `/reportes/security?fecha=${fecha}`;
      const { data } = await API.get(url);

      dispatch({
        type: "GETS_SECURITY_HISTORY",
        payload: data.list,
      });
    } catch (error) {
      handleError(error);
      handleUnauthorizedError(error);
    }
  };

  const getReportsHistory = async (fecha, direccion) => {
    try {
      const url = `/reportes/historial?fecha=${fecha}&direccion=${direccion}`;
      const { data } = await API.get(url);
      dispatch({
        type: "GETS_REPORTS_HISTORY",
        payload: data.list,
      });
    } catch (error) {
      handleError(error);
      handleUnauthorizedError(error);
    }
  };

  const getsGuards = async () => {
    try {
      const url = "/reportes/guardias";
      const { data } = await API.get(url);

      dispatch({
        type: "GETS_GUARDS",
        payload: data.list,
      });
    } catch (error) {
      handleError(error);
      handleUnauthorizedError(error);
    }
  };

  const putGuardInfo = async (process, usuario, dataForm) => {
    try {
      const url = `/reportes/delguardia?process=${process}&usuario=${usuario}`;
      await API.put(url);
      await getsGuards();
      toast({
        description: "Se ha completado la acción con éxito.",
        status: "success",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      handleError(error);
      handleUnauthorizedError(error);
    }
  };

  const getsDefaulters = async () => {
    try {
      const url = "/reportes/morosos"
      const {data} = await API.get(url)
      console.log(data)

      dispatch({
        type: "GETS_DEFAULTERS",
        payload: data.list
      })
    } catch (error) {
      handleError(error);
      handleUnauthorizedError(error);
    }
  }

  const handleError = (error) => {
    console.log(error);
  };

  const handleUnauthorizedError = (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      setAuthToken(null);
      dispatch({
        type: "LOGOUT",
        payload: {},
      });
      window.location.href = "/";
    }
  };

  return (
    <StoreContext.Provider
      value={{
        ...state,
        createResident,
        getContributionsByRange,
        getExpensesByDateRange,
        getInfoContributions,
        getInfoDashboard,
        getReportsHistory,
        getResidentExpirationList,
        getResidentHistory,
        getsAddressList,
        getsDefaulters,
        getsGuards,
        getsSecurityHistory,
        getStatusResident,
        getsVehiclesAuthorized,
        getsVehiclesSearch,
        getsVisits,
        handleError,
        postContributionAdmin,
        postRegisterExpense,
        putContributionAdmin,
        putExpenseAdmin,
        putGuardInfo,
        putResidentExpiration,
        putResidentVisit,
        registerNewVisit,
        registerResidentVehicle,
        registerServiceVisit,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

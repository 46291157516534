/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, ScaleFade, SimpleGrid } from "@chakra-ui/react";
import { RenderLoading } from "../components/RenderAlert";
import { FrequentVisitsModule } from "../components/modules/FrequentVisitsModule";
import { useAuth } from "../hooks/useAuth";
import { useStore } from "../hooks/useStore";
import {  RegisterResidentModule } from "../components/modules/RegisterResidentModule";
import {  RegisterVehicleModule } from "../components/modules/RegisterVehicleModule";
import { ActiveVisitsModule } from "../components/modules/ActiveVisitsModule";
import { ActiveServicesModule } from "../components/modules/ActiveServicesModule";

export const VisitsScreen = () => {
  const { usuario } = useAuth();
  const { dashboardInfo, getInfoDashboard } = useStore();
  const [isLoading, setisLoading] = useState(false);
  const {myCars, residentes, frecuentes, activas, servactive} = dashboardInfo;

  useEffect(() => {
    const fetchResidentInfo = async () => {
      setisLoading(true);
      await getInfoDashboard(usuario.dir);
      setisLoading(false);
    };

    fetchResidentInfo();
  }, []);

  return (
    <>
      {isLoading ? (
        <RenderLoading state={isLoading} />
      ) : (
        <ScaleFade initialScale={0.9} in={true}>
          <Box className="neumorphism--container" padding={5} bg={"white"}>
            <SimpleGrid columns={[1, 1, 2]} spacing={4} mt={4}>
              <ActiveVisitsModule active={activas} />
              <ActiveServicesModule servicesActives={servactive} />
              <FrequentVisitsModule frequent={frecuentes} />
              <RegisterResidentModule residents={residentes} direccion={usuario.dir} />
            </SimpleGrid>
            <RegisterVehicleModule vehicles={myCars} />
          </Box>
        </ScaleFade>
      )}
    </>
  );
};

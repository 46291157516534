import { FormControl, FormLabel, Input } from "@chakra-ui/react";
import React from "react";

export const InputForm = ({ label, children }) => {
  return (
    <FormControl mt={2}>
      <FormLabel>{label}</FormLabel>
      {children}
    </FormControl>
  );
};

export const InputNormal = ({
  value,
  handleBlur,
  handleChange,
  type,
  name,
  label,
}) => {
  return (
    <InputForm label={label}>
      <Input
        autoComplete="off"
        borderColor="#a9a9a9"
        name={name}
        onBlur={handleBlur}
        onChange={handleChange}
        type={type}
        value={value}
        _hover={{
          borderColor: "#a9a9a9",
        }}
        _focus={{ borderColor: "#262626" }}
      />
    </InputForm>
  );
};

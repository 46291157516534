import React, { useRef, useState } from "react";
import {
  Button,
  Center,
  Flex,
  FormControl,
  HStack,
  Heading,
  Spinner,
  Stack,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { API } from "../../api/config";
import { useNavigate } from "react-router-dom";

export const ValidateScreen = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [inputValues, setInputValues] = useState(["", "", "", ""]);
  const [verificationState, setVerificationState] = useState(false);

  const handleInputChange = (e, index) => {
    let value = e.target.value;
    value = value.toUpperCase();
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);

    if (value !== "" && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    } else if (value === "" && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("text");
    const pastedTextUpper = pastedText.toUpperCase();

    if (pastedTextUpper.length === 7) {
      const newInputValues = pastedTextUpper.split("");
      setInputValues(newInputValues);

      newInputValues.forEach((value, index) => {
        if (inputRefs[index] && inputRefs[index].current) {
          inputRefs[index].current.value = value;
        }
      });
    }
  };

  const verifyPin = async () => {
    if (verificationState) {
      return; // Evitar llamadas duplicadas mientras se verifica
    }

    setVerificationState(true);
    const pin = inputValues.join("");

    try {
      const url = `/residente/code?codigo=${pin}`;
      const { data } = await API.get(url);

      const dataResidente = data.lista[0];
      const dataResidenteJSON = JSON.stringify(dataResidente);
      sessionStorage.setItem("residente", dataResidenteJSON);
      setVerificationState(false);
      navigate("/auth/registro");
    } catch (error) {
      setVerificationState(false);
      toast({
        description: "Código incorrecto.",
        status: "error",
        position: "bottom-left",
        variant: "left-accent",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const isButtonDisabled = inputValues.join("").length !== 4;
  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack
        spacing={4}
        w={"full"}
        maxW={"sm"}
        bg={useColorModeValue("white", "gray.700")}
        rounded={"xl"}
        boxShadow={"lg"}
        p={6}
        my={10}
      >
        <Center>
          <Heading lineHeight={1.1} fontSize={{ base: "2xl", md: "3xl" }}>
            Verifica tu codigo
          </Heading>
        </Center>
        <Center
          fontSize={{ base: "sm", sm: "md" }}
          color={useColorModeValue("gray.800", "gray.400")}
        >
          Si aun no cuentas con tu codigo, solicitalo a tu comite administrativo
        </Center>

        <FormControl>
          <Center>
            <HStack>
              {inputValues.map((value, index) => (
                <input
                  className="input-validate"
                  key={index}
                  disabled={verificationState}
                  maxLength={1}
                  onChange={(e) => handleInputChange(e, index)}
                  onPaste={handlePaste}
                  ref={inputRefs[index]}
                  type="text"
                  value={value}
                />
              ))}
            </HStack>
          </Center>
        </FormControl>
        <Stack spacing={6}>
          {!verificationState ? (
            <Button
              isDisabled={isButtonDisabled}
              onClick={verifyPin}
              bg={isButtonDisabled ? "gray.400" : "cyan.400"}
              _hover={{ bg: "cyan.500" }}
            >
              Verificar
            </Button>
          ) : (
            <Button isDisabled>
              <Spinner size="sm" marginRight={"0.25rem"} color="blue.500" />
              Verificando...
            </Button>
          )}
        </Stack>
      </Stack>
    </Flex>
  );
};

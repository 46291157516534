/* 
  contiene todas las paginas asociadas a la autentificacion 
*/

import React from "react";
import { Routes, Route } from "react-router-dom";
import { LoginScreen } from "../screens/auth/LoginScreen";
import { RegisterScreen } from "../screens/auth/RegisterScreen";
import { ValidateScreen } from "../screens/auth/ValidateScreen";
import { CheckUserScreen } from "../screens/auth/CheckUserScreen";
import { ChangePasswordScreen } from "../screens/auth/ChangePasswordScreen";

const AuthRouter = () => {
  return (
    <div className="contenedor__auth--main">
      <div className="contenedor__auth--box">
        <Routes>
          <Route end path="/login" element={<LoginScreen />} />
          <Route end path="/validate-code" element={<ValidateScreen />} />
          <Route end path="/registro" element={<RegisterScreen />} />
          <Route end path="/check-user" element={<CheckUserScreen />} />
          <Route end path="/change-password" element={<ChangePasswordScreen />} />
        </Routes>
      </div>
    </div>
  );
};

export default AuthRouter;

import React from "react";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa6";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../hooks/useAuth";
import { useStore } from "../../hooks/useStore";

const validationSchema = Yup.object().shape({
  idResidencia: Yup.string().required("Dirección es obligatorio"),
  cantidad: Yup.number().required("Cantidad es obligatorio"),
});

export const ContributionForm = ({
  startDate,
  endDate,
  selectedAddress,
  setSelectedAddress,
  value,
}) => {
  const { usuario } = useAuth();
  const { postContributionAdmin } = useStore();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isConfirmationOpen,
    onOpen: onConfirmationOpen,
    onClose: onConfirmationClose,
  } = useDisclosure();

  const initialValues = {
    idResidencia: selectedAddress ? selectedAddress.trim() : "",
    cantidad: "",
    comentario: "",
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      setSubmitting(true);
      const currentDate = new Date();
      const nextMonthDate = new Date(currentDate);
      nextMonthDate.setMonth(currentDate.getMonth() + 1);
      nextMonthDate.setDate(21);

      if (currentDate.getMonth() === 11) {
        nextMonthDate.setFullYear(nextMonthDate.getFullYear());
      }

      const dataForm = {
        ...values,
        idResidencia: values.idResidencia.trim(),
        vencimiento: nextMonthDate,
      };
      console.log(dataForm)
      await postContributionAdmin(usuario.nombre, dataForm, startDate, endDate);
      setSubmitting(false);
      resetForm(initialValues);
      onClose();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Button
        leftIcon={<FaPlus />}
        boxShadow="md"
        colorScheme="twitter"
        variant="solid"
        onClick={() => {
          setSelectedAddress(value);
          onOpen();
        }}
      >
        Agregar aporte
      </Button>

      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <Form>
                  <Stack spacing={4} w={"full"} p={6} mt={4}>
                    <Heading
                      lineHeight={1.1}
                      fontSize={{ base: "xl", sm: "2xl" }}
                    >
                      Nuevo Aporte
                    </Heading>

                    <Field name="idResidencia">
                      {({ field, form }) => (
                        <FormControl
                          id="idResidencia"
                          isInvalid={
                            form.errors.idResidencia &&
                            form.touched.idResidencia
                          }
                        >
                          <FormLabel>Dirección</FormLabel>
                          <Input {...field} type="text" />
                          <ErrorMessage name="idResidencia" />
                        </FormControl>
                      )}
                    </Field>

                    <Field name="cantidad">
                      {({ field, form }) => (
                        <FormControl
                          id="cantidad"
                          isInvalid={
                            form.errors.cantidad && form.touched.cantidad
                          }
                        >
                          <FormLabel>Cantidad</FormLabel>
                          <Input {...field} placeholder="$" type="number" />
                          <ErrorMessage name="cantidad" />
                        </FormControl>
                      )}
                    </Field>

                    <Text fontWeight={"500"}>Comentario</Text>
                    <Field name="comentario">
                      {({ field }) => (
                        <Textarea
                          {...field}
                          label="Comentario"
                          name="comentario"
                        />
                      )}
                    </Field>

                    <Stack spacing={6} direction={["column", "row"]}>
                      <Button
                        colorScheme="twitter"
                        w="full"
                        onClick={onConfirmationOpen}
                        isLoading={formik.isSubmitting}
                      >
                        Finalizar
                      </Button>
                      <Button
                        colorScheme="red"
                        w="full"
                        type="button"
                        onClick={() => {
                          formik.handleReset();
                          onClose();
                        }}
                      >
                        Cancelar
                      </Button>
                    </Stack>
                  </Stack>

                  <Modal
                    isOpen={isConfirmationOpen}
                    onClose={onConfirmationClose}
                    isCentered
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <ModalBody p={6}>
                        <Heading as="h2" size="md" mb={4}>
                          ¿Estás seguro de agregar este aporte?
                        </Heading>

                        <Flex justify="flex-end" mt={4}>
                          <Button
                            colorScheme="green"
                            mr={2}
                            onClick={() => {
                              onConfirmationClose();
                              formik.handleSubmit();
                            }}
                          >
                            Confirmar
                          </Button>
                          <Button
                            colorScheme="red"
                            onClick={onConfirmationClose}
                          >
                            Cancelar
                          </Button>
                        </Flex>
                      </ModalBody>
                    </ModalContent>
                  </Modal>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

/* 
  maneja las rutas privadas de la aplicacion, como el login y el registro
*/

import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

const PrivateRouter = ({ children }) => {
  const { isAuthenticated, rol } = useAuth();
  const navigate = useNavigate();
  const [initialRedirectDone, setInitialRedirectDone] = useState(false);

  useEffect(() => {
    const getInitialPath = () => {
      if (isAuthenticated) {
        if (rol === 4) {
          return "/caseta";
        } else if (rol === 7) {
          return "/residente/resumen";
        } else if (rol === 2) {
          return "/administracion/dashboard";
        } else if (rol === 3) {
          return "/administracion/aportaciones";
        }
      }

      return "/auth/login";
    };

    // Solo redirige si está autenticado y aún no ha accedido a ninguna ruta
    if (isAuthenticated && !initialRedirectDone) {
      navigate(getInitialPath(), { replace: true });
      setInitialRedirectDone(true);
    }
  }, [isAuthenticated, rol, initialRedirectDone, navigate]);

  return isAuthenticated ? children : <Navigate to="/auth/login" />;
};

export default PrivateRouter;

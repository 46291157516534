import React from "react";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FaPenToSquare } from "react-icons/fa6";
import { useStore } from "../../hooks/useStore";

const validationSchema = Yup.object().shape({
  idResidencia: Yup.string().required("Dirección es obligatorio"),
  cantidad: Yup.number().required("Cantidad es obligatorio"),
});

export const ContributionEditForm = ({ contribution, startDate, endDate }) => {
  const { putContributionAdmin } = useStore();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isConfirmationOpen,
    onOpen: onConfirmationOpen,
    onClose: onConfirmationClose,
  } = useDisclosure();

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      await putContributionAdmin(1, values, startDate, endDate);
      setSubmitting(false);
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Tooltip hasArrow label="Editar aporte">
        <IconButton
          aria-label="editar aporte"
          icon={<FaPenToSquare />}
          onClick={onOpen}
          size="lg"
          variant="filled"
        />
      </Tooltip>

      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={contribution}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <Form>
                  <Stack spacing={4} w={"full"} p={6} mt={4}>
                    <Heading
                      lineHeight={1.1}
                      fontSize={{ base: "xl", sm: "2xl" }}
                    >
                      Editando Aporte
                    </Heading>

                    <Field name="idResidencia">
                      {({ field, form }) => (
                        <FormControl
                          id="idResidencia"
                          isInvalid={
                            form.errors.idResidencia &&
                            form.touched.idResidencia
                          }
                        >
                          <FormLabel>Dirección</FormLabel>
                          <Input {...field} type="text" />
                          <ErrorMessage name="idResidencia" />
                        </FormControl>
                      )}
                    </Field>

                    <Field name="cantidad">
                      {({ field, form }) => (
                        <FormControl
                          id="cantidad"
                          isInvalid={
                            form.errors.cantidad && form.touched.cantidad
                          }
                        >
                          <FormLabel>Cantidad</FormLabel>
                          <Input {...field} type="number" />
                          <ErrorMessage name="cantidad" />
                        </FormControl>
                      )}
                    </Field>

                    <Text fontWeight={"500"}>Comentario</Text>
                    <Field name="comentario">
                      {({ field }) => (
                        <Textarea
                          {...field}
                          label="Comentario"
                          name="comentario"
                        />
                      )}
                    </Field>

                    <Stack spacing={6} direction={["column", "row"]}>
                      <Button
                        colorScheme="twitter"
                        isLoading={formik.isSubmitting}
                        onClick={onConfirmationOpen}
                        w="full"
                      >
                        Guardar
                      </Button>
                      <Button
                        colorScheme="red"
                        w="full"
                        type="button"
                        onClick={() => {
                          formik.handleReset();
                          onClose();
                        }}
                      >
                        Cancelar
                      </Button>
                    </Stack>
                  </Stack>

                  <Modal
                    isOpen={isConfirmationOpen}
                    onClose={onConfirmationClose}
                    isCentered
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <ModalBody p={6}>
                        <Heading as="h2" size="md" mb={4}>
                          ¿Estás seguro de editar este registro?
                        </Heading>

                        <Flex justify="flex-end" mt={4}>
                          <Button
                            colorScheme="green"
                            mr={2}
                            onClick={() => {
                              onConfirmationClose();
                              formik.handleSubmit();
                            }}
                          >
                            Confirmar
                          </Button>
                          <Button
                            colorScheme="red"
                            onClick={onConfirmationClose}
                          >
                            Cancelar
                          </Button>
                        </Flex>
                      </ModalBody>
                    </ModalContent>
                  </Modal>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

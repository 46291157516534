import React from "react";
import {
  Card,
  CardBody,
  Flex,
  Heading,
  IconButton,
  List,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { FaEllipsisVertical, FaTrash, FaUserXmark } from "react-icons/fa6";
import { useStore } from "../../hooks/useStore";
import { useAuth } from "../../hooks/useAuth";
import { MessageBox } from "../MessageBox";

export const ActiveVisitsModule = ({ active }) => {
  const { usuario } = useAuth();
  const { handleError, putResidentVisit } = useStore();
  const { dir: direccion, nombre } = usuario;

  const handleRemove = async (id) => {
    try {
      await putResidentVisit(2, id, nombre, direccion);
    } catch (error) {
      handleError(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await putResidentVisit(5, id, nombre, direccion);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Flex
      mt={4}
      boxShadow="md"
      direction="column"
      padding={4}
      bg="#edf2f7"
      alignItems="center"
      borderRadius="lg"
    >
      <Flex w="100%" justifyContent="space-between" alignItems="center">
        <Heading as="h2" size="lg">
          Visitas Activas
        </Heading>
      </Flex>

      {active?.length > 0 ? (
        <List spacing={2} w="full" mt={4}>
          {active.map(
            (visit, index) =>
              visit.nombre && (
                <Card key={index} w="full" boxShadow="md">
                  <CardBody
                    key={index}
                    alignItems="center"
                    borderColor="green.400"
                    borderLeftWidth={8}
                    borderRadius="lg"
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Text fontSize="lg">{visit.nombre}</Text>
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<FaEllipsisVertical />}
                        variant="filled"
                      />
                      <MenuList>
                        <MenuItem
                          icon={<FaUserXmark />}
                          onClick={() => handleRemove(visit.idVehiculo)}
                        >
                          Quitar
                        </MenuItem>
                        <MenuItem
                          icon={<FaTrash />}
                          onClick={() => handleDelete(visit.idVehiculo)}
                        >
                          Eliminar
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </CardBody>
                </Card>
              )
          )}
        </List>
      ) : (
        <Flex width="100%" h="100%" justify="center" align="center">
          <MessageBox description="Sin registros asociado" status="info" />
        </Flex>
      )}
    </Flex>
  );
};

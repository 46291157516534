/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  ScaleFade,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import { registerLocale } from "react-datepicker";
import { format } from "date-fns";

import { useAuth } from "../hooks/useAuth";
import { RenderLoading } from "../components/RenderAlert";
import { useStore } from "../hooks/useStore";
import { TablaUserHistory } from "../components/tables/TablaUserHistory";
import { MessageBox } from "../components/MessageBox";

registerLocale("es", es);

export const UserHistoryScreen = () => {
  const { usuario } = useAuth();
  const { getResidentHistory, residentHistory } = useStore();
  const [isLoading, setisLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);

  const handleDateChange = (date) => {
    setIsOpen(!isOpen);
    setSelectedDate(date);
  };

  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const formatDateToSend = (date) => {
    return format(date, "yyyy-MM-dd"); // Formatea la fecha como 'yyyy-MM-dd'
  };

  const handleSearch = async () => {
    try {
      const formattedDate = formatDateToSend(selectedDate);
      console.log(formattedDate);
      setisLoading(true);
      await getResidentHistory(formattedDate, usuario.dir);
      setisLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchResidentInfo = async () => {
      setisLoading(true);
      await getResidentHistory(formatDateToSend(selectedDate), usuario.dir);
      setisLoading(false);
    };

    fetchResidentInfo();
  }, []);
  return (
    <>
      {isLoading ? (
        <RenderLoading state={isLoading} />
      ) : (
        <ScaleFade initialScale={0.9} in={true}>
          <Box className="neumorphism--container" padding={5} bg={"white"}>
            <Heading
              fontSize={{ base: "2xl", sm: "4xl" }}
              fontWeight="bold"
              textAlign="center"
              mb={4}
            >
              
            </Heading>

            <Container maxW={"full"}>
              <Flex position="relative">
                <Button colorScheme="blue" mr={2} onClick={handleClick}>
                  {format(selectedDate, "yyyy-MM-dd")}
                </Button>
                <Box
                  position="absolute"
                  top="calc(100% + 10px)"
                  left="0"
                  zIndex="999"
                >
                  {isOpen && (
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      inline
                      maxDate={new Date()}
                      onClickOutside={handleClick}
                      locale="es"
                    />
                  )}
                </Box>

                <Button onClick={handleSearch}>Ver Historial</Button>
              </Flex>

              {residentHistory.length > 0 ? (
                <Box mt={4}>
                  <TablaUserHistory userHistory={residentHistory} />
                </Box>
              ) : (
                <Flex justify="center" mt={4}>
                  <MessageBox
                    message="No hay historial que visualizar"
                    description="Seleccione una nueva fecha para ver su historial"
                    status="info"
                  />
                </Flex>
              )}
            </Container>
          </Box>
        </ScaleFade>
      )}
    </>
  );
};

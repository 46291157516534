import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";

export const TablaDefaulters = ({ defaulters }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * 5;
  const indexOfFirstItem = indexOfLastItem - 5;
  const currentItems = defaulters?.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(defaulters?.length / 5);

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const renderPagination = () => {
    const adjacentPages = 2;
    const totalAdjacentPages = adjacentPages * 2 + 1;

    let startPage = Math.max(1, currentPage - adjacentPages);
    let endPage = Math.min(totalPages, startPage + totalAdjacentPages - 1);

    if (endPage - startPage < totalAdjacentPages - 1) {
      startPage = Math.max(1, endPage - totalAdjacentPages + 1);
    }

    return (
      <>
        <IconButton
          aria-label="pagina anterior"
          bg="white"
          borderRadius="full"
          icon={<Icon as={FaAngleLeft} />}
          onClick={() =>
            setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
          }
          variant="outline"
          isDisabled={currentPage === 1}
        />

        {pageNumbers.slice(startPage - 1, endPage).map((pageNumber) => (
          <Button
            key={pageNumber}
            colorScheme="twitter"
            borderRadius="full"
            mx={1}
            onClick={() => goToPage(pageNumber)}
            variant={pageNumber === currentPage ? "solid" : "outline"}
            width="30px"
            style={{
              fontWeight: pageNumber === currentPage ? "bold" : "normal",
            }}
          >
            {pageNumber}
          </Button>
        ))}

        <IconButton
          aria-label="pagina siguiente"
          bg="white"
          borderRadius="full"
          icon={<Icon as={FaAngleRight} />}
          variant="outline"
          isDisabled={currentPage === totalPages}
          onClick={() =>
            setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))
          }
        />
      </>
    );
  };

  // Cambiar a una página específica
  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      {currentItems?.length > 0 && (
        <TableContainer borderRadius={10} boxShadow="md" bg="white">
          <Table variant="simple">
            <TableCaption>
              <Flex justify="space-between" align="center">
                <Box>{renderPagination()}</Box>
              </Flex>
            </TableCaption>
            <Thead bg="cyan.400">
              <Tr>
                <Th>dirección</Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentItems.map((value, index) => (
                <Tr key={index}>
                  <Td>{value}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

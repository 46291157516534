import React from "react";
import {
    Button,
    Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { FaPenToSquare } from "react-icons/fa6";
import { useAuth } from "../../hooks/useAuth";
import { useStore } from "../../hooks/useStore";

const validationSchema = Yup.object().shape({
    nombre: Yup.string().required("Nombre es obligatorio"),
    correo: Yup.string().required("Correo es obligatorio"),
    telefono: Yup.number().required("Teléfono es obligatorio"),
  });

export const GuardEditForm = ({ guard }) => {
  const { putGuardInfo } = useStore();
  const { usuario } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isConfirmationOpen,
    onOpen: onConfirmationOpen,
    onClose: onConfirmationClose,
  } = useDisclosure();

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      await putGuardInfo(1, usuario.nombre, values);
      setSubmitting(false);
      onClose();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Tooltip hasArrow label="Editar">
        <IconButton
          aria-label="editar guardia"
          icon={<FaPenToSquare />}
          onClick={onOpen}
          size="lg"
          variant="filled"
        />
      </Tooltip>

      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={guard}
                validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <Form>
                  <Stack spacing={4} w={"full"} p={6} mt={4}>
                    <Heading
                      lineHeight={1.1}
                      fontSize={{ base: "xl", sm: "2xl" }}
                    >
                      Editando información
                    </Heading>

                    <Field name="nombre">
                      {({ field, form }) => (
                        <FormControl
                          id="nombre"
                          isInvalid={form.errors.nombre && form.touched.nombre}
                        >
                          <FormLabel>Nombre</FormLabel>
                          <Input {...field} type="text" />
                          <ErrorMessage name="nombre" />
                        </FormControl>
                      )}
                    </Field>

                    <Field name="correo">
                      {({ field, form }) => (
                        <FormControl
                          id="correo"
                          isInvalid={form.errors.correo && form.touched.correo}
                        >
                          <FormLabel>Correo electrónico</FormLabel>
                          <Input {...field} type="text" />
                          <ErrorMessage name="correo" />
                        </FormControl>
                      )}
                    </Field>

                    <Field name="telefono">
                      {({ field, form }) => (
                        <FormControl
                          id="telefono"
                          isInvalid={
                            form.errors.telefono && form.touched.telefono
                          }
                        >
                          <FormLabel>Teléfono</FormLabel>
                          <Input {...field} type="number" />
                          <ErrorMessage name="telefono" />
                        </FormControl>
                      )}
                    </Field>

                    <Stack spacing={6} direction={["column", "row"]}>
                      <Button
                        bg="blue.400"
                        color="white"
                        w="full"
                        onClick={onConfirmationOpen}
                        isLoading={formik.isSubmitting}
                        _hover={{
                          bg: "blue.500",
                        }}
                      >
                        Guardar
                      </Button>
                      <Button
                        bg={"red.400"}
                        color={"white"}
                        w="full"
                        type="button"
                        onClick={() => {
                          formik.handleReset();
                          onClose();
                        }}
                        _hover={{
                          bg: "red.500",
                        }}
                      >
                        Cancelar
                      </Button>
                    </Stack>
                  </Stack>

                  <Modal
                    isOpen={isConfirmationOpen}
                    onClose={onConfirmationClose}
                    isCentered
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <ModalBody p={6}>
                        <Heading as="h2" size="md" mb={4}>
                          ¿Estás seguro de editar este registro?
                        </Heading>

                        <Flex justify="flex-end" mt={4}>
                          <Button
                            colorScheme="green"
                            mr={2}
                            onClick={() => {
                              onConfirmationClose();
                              formik.handleSubmit();
                            }}
                          >
                            Confirmar
                          </Button>
                          <Button
                            colorScheme="red"
                            onClick={onConfirmationClose}
                          >
                            Cancelar
                          </Button>
                        </Flex>
                      </ModalBody>
                    </ModalContent>
                  </Modal>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
